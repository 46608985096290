const dataName='data';
export const setUserInfo=(data)=> {
    sessionStorage.setItem(dataName, JSON.stringify(data));
}

export const getUserInfo=()=>{
    return JSON.parse(sessionStorage.getItem(dataName) || "{}") ;
}


export const clearUserInfo=()=> {
    sessionStorage.removeItem(dataName);
}


const asyncSessionStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
          //  localStorage.setItem(key, value);
          sessionStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            //return localStorage.getItem(key);
            return sessionStorage.getItem(key);
        });
    }
};


export const getUserInfoAsync=async()=>{
    //return JSON.parse(asyncLocalStorage.getItem('tfcc') || "{}") ;
    let userInfo = await asyncSessionStorage.getItem(dataName) || "{}";
    // console.log('userinfo-x');
    // console.log(JSON.parse(userInfo));

    return JSON.parse(userInfo);
  //  return JSON.parse( await asyncLocalStorage.getItem('tfcc') || "{}") ;
    //return asyncLocalStorage.getItem('mykey');

    // asyncLocalStorage.setItem('mykey', data).then(function () {
    //     return asyncLocalStorage.getItem('mykey');
    // }).then(function (value) {
    //     console.log('Value has been set to:', value);
    // });

}


// start package selected by user
export const setPackageInfoSelected=(IdPackage,data)=>{
    sessionStorage.setItem(IdPackage, JSON.stringify(data));
}
export const getPackageInfoSelected=(IdPackage)=>{
    // console.log("sessionStorage.getItem(IdPackage)",sessionStorage.getItem(IdPackage));
    // return JSON.parse(sessionStorage.getItem(dataName) || "{}") ;
    // return JSON.parse(sessionStorage.getItem(IdPackage) || "{}") ;
    // return JSON.parse(sessionStorage.getItem(dataName) || "{}") ;
    // console.log("sessionStorage.getItem(dataName) || '{}'",sessionStorage.getItem(dataName) || "{}");

    // console.log("getPackageInfoSelectedgetPackageInfoSelected");

    return JSON.parse(sessionStorage.getItem(IdPackage) || "{}") ;
}
export const clearPackageInfoSelected=(IdPackage)=>{
    sessionStorage.removeItem(IdPackage);
}

// end package selected by user