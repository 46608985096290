/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, Button, ModalHeader, Modal, ModalFooter, ModalBody, Row, Form } from 'reactstrap';
import { Controller } from 'react-hook-form';
import InputField from "components/NormalInputField/InputField";
import "./index.scss";
import { useChangePassword } from 'hooks/useChangePassword';
import i18n from "i18next";
import { useForm } from "react-hook-form";


const ChangePassword = ({ isModal, onToggle }) => {
    const changePassword = useChangePassword();

    const { register, handleSubmit, setError, setValue, errors, formState } = useForm({
        mode: "onTouched",
    });

    const [errMessage, setErrMessage] = useState();
    const [userEmail, setUserEmail] = useState();



    const onSubmit = (data) => {

        const { currentPassword, newPassword, confirmPassword } = data;

        let req = {
            email: userEmail,
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmPassword
        }

        const response = changePassword.onChangePassword(req);
        response.then((res) => {
            toggle()
        })
            .catch((error) => {
                //console.log(error.response)
                if (error?.response?.data?.errors)
                    setErrMessage(error?.response?.data?.errors[0])
                else
                    if (error?.response?.data)
                        setErrMessage(error?.response?.data)

                //setErrMessage(error?.response?.data || error?.response?.data?.errors);
            });

        console.log(data)
    }

    //  const [isModal, setIsModal] = React.useState(isModal);
    const toggle = () => {
        onToggle(!isModal);
    }


    useEffect(() => {
        changePassword.onGetUserInfo();
    }, [isModal])

    useEffect(() => {
        setUserEmail(changePassword?.userInfo?.email);
        //console.log(changePassword?.userInfo)
    }, [changePassword?.userInfo])


    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>Toggle</Button> */}

            <Modal funk={true} isOpen={isModal} toggle={toggle} fade={false} centered={true}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={toggle}>{i18n.language == 'en' ? `Change password` : `เปลี่ยนรหัสผ่าน`} </ModalHeader>
                    <ModalBody className='p-4' >
                        <Row> {errMessage} </Row>
                        <Row className='pt-2' >
                            <InputField
                                type="password"
                                name="currentPassword"

                                placeholder={i18n.language == 'en' ? `Current password` : `รหัสผ่านปัจจุบัน`}
                                validationMessage="The password should have a length between 6 and 30 characters."
                                validation={register({
                                    required: true,
                                    minLength: 6,
                                    maxLength: 30,
                                })}
                            //   errors={errors}
                            // disabled={isLoading}
                            />
                        </Row>
                        <Row className='pt-2'>
                            <InputField
                                type="password"
                                name="newPassword"

                                placeholder={i18n.language == 'en' ? `New password` : `รหัสผ่านใหม่`}
                                validationMessage="The password should have a length between 6 and 30 characters."
                                validation={register({
                                    required: true,
                                    minLength: 6,
                                    maxLength: 30,
                                })}
                            //   errors={errors}
                            // disabled={isLoading}
                            />
                        </Row>
                        <Row className='pt-2'>
                            <InputField
                                type="password"
                                name="confirmPassword"

                                placeholder={i18n.language == 'en' ? `Confirm password` : `ยืนยันรหัสผ่าน`}
                                validationMessage="The password should have a length between 6 and 30 characters."
                                validation={register({
                                    required: true,
                                    minLength: 6,
                                    maxLength: 30,
                                })}
                            //   errors={errors}
                            // disabled={isLoading}
                            />
                        </Row>


                    </ModalBody>
                    <ModalFooter>
                        <Button type='submit' color="primary" >{i18n.language == 'en' ? `Change password` : `เปลี่ยนรหัสผ่าน`}</Button>{' '}
                        <Button color="secondary" onClick={toggle}>{i18n.language == 'en' ? `Cancel` : `ยกเลิก`}</Button>
                    </ModalFooter>
                </Form>
            </Modal>

        </div>

        // <div>
        //     <Button
        //         color="danger"
        //         onClick={function noRefCheck() { }}
        //     >
        //         Click Me
        //     </Button>
        //     <Modal
        //         fade={false}
        //         toggle={function noRefCheck() { }}
        //     >
        //         <ModalHeader toggle={function noRefCheck() { }}>
        //             Modal title
        //         </ModalHeader>
        //         <ModalBody>
        //             Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        //         </ModalBody>
        //         <ModalFooter>
        //             <Button
        //                 color="primary"
        //                 onClick={function noRefCheck() { }}
        //             >
        //                 Do Something
        //             </Button>
        //             {' '}
        //             <Button onClick={function noRefCheck() { }}>
        //                 Cancel
        //             </Button>
        //         </ModalFooter>
        //     </Modal>
        // </div>
    )
};

export default ChangePassword;





