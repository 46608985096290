/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

const examAtom = atom({
    key: 'exam',
    default: true
});

const examDataAtom = atom({
    key: 'examData',
    default: {}
});

export { useExamModal };
function useExamModal() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [isModal, setExamModalState] = useRecoilState(examAtom);
    const [examModalData, setExamModalDataState] = useRecoilState(examDataAtom);

    return {
        setExamModal,
        setDisableExamModal,
        isModal: isModal,
        examModalData: examModalData
    }


    function setExamModal({ url, datas, onRefreshCourse, onCompleteCourse }) {
        setExamModalState(false);
        setExamModalDataState({ url, datas, onRefreshCourse, onCompleteCourse });
    }

    function setDisableExamModal() {
        setExamModalState(true)
        setExamModalDataState({});
    }
}
