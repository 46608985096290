/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    //   CardTitle,
    //   CardText,
    Row,
    Col,
    Table,
    CardImg,
} from "reactstrap";

import i18n from "i18next";
import { FaPlay, FaCheckDouble, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import { usePlayVideo } from "hooks/usePlayVideo";
import { useExamModal } from "hooks/useExamModal";
import { useAuthen } from "hooks/useAuthen";
import { useShowCertificate } from "hooks/useShowCertificate";
import moment from "moment";


const PassedCourse = ({ courses, isRegister }) => {

    // const playerActions = usePlayVideo();
    // const examModal = useExamModal();
    // const userActions = useAuthen();
    const showCertificate = useShowCertificate();

    useEffect(() => {
        console.log('courses?.categories');
        console.log(courses?.categories)
    }, [])

    return (
        <>
            {courses?.map((c) => {
                if (c?.items?.length > 0)
                    return (
                        <>
                            <NavLink
                                onClick={() => {
                                    //
                                }}
                            >
                                {/* {c.productCategoryName} ({c?.items?.length}) */}
                            </NavLink>

                            <Table striped >
                                <tbody>
                                    {c?.items?.map(item => {
                                        if (item?.courses?.isComplete)
                                            return (
                                                <tr>
                                                    <td >
                                                        {c.productCategoryName}
                                                    </td>
                                                    <td >
                                                        {item?.courses?.courseCode}
                                                    </td>
                                                    <td>
                                                        <Link to={`/course/${item?.courses?.id}`}>
                                                            {item?.courses?.courseName} ( {item?.courses?.courseNameLocale} )
                                                        </Link><br />
                                                        {i18n.language == 'en' ? `Start At ` : `เริ่มต้น `}{moment(item?.courses?.myCourseStartAt).format('DD-MM-YYYY HH:MM')} {i18n.language == 'en' ? `Finish At ` : `ถึง`} {moment(item?.courses?.myCourseCompleteAt).format('DD-MM-YYYY HH:MM')}

                                                    </td>
                                                    <td>
                                                        <Link to={`#`}
                                                            onClick={() => {

                                                                let datas = {
                                                                    datas: {
                                                                        myCourseId: item?.courses?.myCourseId,
                                                                        myCourseOwnerBy: item?.courses?.myCourseOwnerBy,
                                                                    },
                                                                };

                                                                showCertificate.setShow(datas)
                                                            }}

                                                        >
                                                            Certificate
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                        </>)
            }
            )}

        </>)
}

export default PassedCourse;