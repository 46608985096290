/* eslint-disable */
import "./rowPoster.scss";

import React, { useState, useEffect, useMemo } from "react";
import { BASE_IMG_URL, FALLBACK_IMG_URL, BASE_API_URL } from "../../requests";
import { useDispatch } from "react-redux";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import { FaPlus, FaMinus, FaPlay, FaChevronDown } from "react-icons/fa";
import useGenreConversion from "../../hooks/useGenreConversion";
import { showModalDetail } from "../../redux/modal/modal.actions";
import { showModalPlay } from "../../redux/playmodal/modal.actions";
import { useHistory } from "react-router-dom";

//import { useRecoilValue } from "recoil";
//import { playerAtom } from "_state";
//import { usePlayerActions } from "_actions";
import { usePlayVideo } from "hooks/usePlayVideo";
import defaultBanner from 'assets/images/banners/banner.jpg';
import ImageRenderer from 'components/ImageRenderer/ImageRenderer';
import apiEndpoint from 'services/api-config'


export const generateNumber = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min)
}



import { Link } from "react-router-dom";

const banners = [
	'/image/5748c1c55862474cb00baaad72cc0c0c/1.jpg',
	'/image/f43d7665012a426c80ff31f057cf5977/2.jpg',
	'/image/a1aa271b949841ac98edd0b6301019d6/3.jpg',
	'/image/58308e85f4f641ffa57145cc8afe371f/4.jpg',
	'/image/f284475c4bb74212bfe9c5c3e562ae67/5.jpg',
	'/image/4d08b2d9a0e84d2ba4b2284e79339a87/6.jpg',
	'/image/280f7c88bf36418280b6b27a76e641c8/7.jpg',
	'/image/9ab13567f9fd4e7d8012f2eb1627136a/8.jpg',
	'/image/1850f585dc9f4836850cebd8efc396d8/9.jpg',
	'/image/54574c5c008347cdab6307ca4c338dd1/10.jpg',
	'/image/89fd9346db444250af8f198746689f07/11.jpg',
	'/image/90b528807c7f42218c5cfbf785a77828/12.jpg',
	'/image/94e6be3bf17243268177ebc999d1ce60/13.jpg',
	'/image/fdcc1aad4f904910ad27d74d07338b58/14.jpg',
	'/image/059cfeb7d2e742f99aa644d541a8bf97/15.jpg',
	'/image/3b2ea1e10ece43f88578fc021c7b7028/16.jpg',
	'/image/ccc8e9d5bd1d4f9884ae4a8b9ac22b7f/17.jpg'

]



const RowPoster2 = ({ title, code, description, poster, isLarge, courseId, coverImage, posterImageUrl, posterImageId, isLearning }) => {

	const image_width = 259;
	const image_height = 146;
	const bannerImageUrl = banners[generateNumber(0, banners.length - 1)];

	const baseUrl = `https://imgproxy.devz.sbs/unsafe/${image_width}x${image_height}/imgproxy.devz.sbs${bannerImageUrl}`;

	const history = useHistory();
	const [bannerImage, setBannerImage] = useState(defaultBanner)
	const [loadingImage, setLoadingImage] = useState(true);

	const playerActions = usePlayVideo();
	const item = {
		title: title,
		overview: 'xxxx',
		original_name: '',
		original_title: '',
		name: '',
		genre_ids: '',
		poster_path: `${'images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg'}`,
		titleImageUrl: `${BASE_API_URL}/${'images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg'}`,
		//  backdrop_path:`${BASE_API_URL}/${'images/products/elearning/chris-montgomery-smgTvepind4-unsplash.jpg'}` \
	};

	let fallbackTitle = title;//|| original_title || name || original_name;
	let genres = ["หลักสูตรบังคับ", "ตำแหน่ง", "พื้นฐาน"]
	//let isLarge = true;
	let isFavourite = false;
	let overview = description?.substring(0, 248) + '...';
	//let code = "GO112T9V82";


	const onError = (ev) => {
		//setDefaultUrl(defaultBanner);

		// https://imgproxy.devz.sbs/unsafe/800x400/imgproxy.devz.sbs/image/0cf58437a71b4302a8a808e10cb90182/photo99.jpg

		const ran = generateNumber(1, 10000);
		const imgUrl = baseUrl;//isLarge ? baseUrl + "/images/banners/259/385?ran=" + courseId : baseUrl + "/images/banners/259/146?ran=" + courseId;
		setBannerImage(imgUrl);
		//console.log("load imag  error !!!!...");
		ev.target.src = imgUrl;

	};

	//const genresConverted = useGenreConversion(genre_ids);
	const dispatch = useDispatch();

	const handleAdd = event => {
		event.stopPropagation();
		//dispatch(addToFavourites({ ...item, isFavourite }));
	};
	const handleRemove = event => {
		event.stopPropagation();
		//dispatch(removeFromFavourites({ ...item, isFavourite }));
	};
	const handlePlayModalOpening = () => {
		playerActions.setPlay({});
	}

	const handleModalOpening = () => {
		//event.preventDefault();
		if (isLearning) {
			const location = {
				pathname: `/course/${courseId}`
			}

			console.log('xxxx>>yyy', isLearning);

			// setTimeout(() => {
			// 	history.push(`/course/${courseId}`)
			// }, 1000)

			history.push(`/course/${courseId}`)

			//history.push(`/course/${courseId}`)
		} else {
			dispatch(showModalDetail({ item, courseId, code, description, fallbackTitle, genres, isFavourite, overview, poster, posterImageUrl, posterImageId }));
		}
	}

	const handlePlayAction = event => {
		event.stopPropagation();

	};

	useEffect(() => {
		//const baseUrl = "https://mylearn-api.devz.sbs/api";

		console.log(' banner image >>> ' + coverImage);

		const ran = generateNumber(1, 10000);
		const imgUrl = coverImage ? coverImage : baseUrl;//isLarge ? baseUrl + "/images/banners/259/385?ran=" + courseId : baseUrl + "/images/banners/259/146?ran=" + courseId;
		setBannerImage(imgUrl);

	}, [courseId])

	return (

		<>

			<div
				className={`Row__poster ${isLarge && "Row__poster--big"}`}
				onClick={handleModalOpening}
			>
				{isLarge ? (


					// <img src={poster?.posterLargeUrl} alt={fallbackTitle} />

					<ImageRenderer
						key={title}
						url={bannerImage}
						thumb={'https://i.imgur.com/67r0FdBm.jpg'}
						width={1920}
						height={1080}
						onError={onError}
					/>

				) : (
					// <img src={poster?.posterUrl} alt={fallbackTitle} />
					// <img src={bannerImage} alt={fallbackTitle} />
					// <DummyImage w={340} h={170} />
					<ImageRenderer
						key={title}
						url={bannerImage}
						thumb={'https://i.imgur.com/67r0FdBm.jpg'}
						width={1920}
						height={1080}
						onError={onError}
					/>

				)}
				{/* <div className="Row__poster-info">
					<div className="Row__poster-info--iconswrp">
						<Link
							className="Row__poster-info--icon icon--play"
							//	onClick={handlePlayModalOpening}
							onClick={handlePlayAction}
							to={`/course/${courseId}`}
						//to={`/course`}
						>
							<FaPlay />
						</Link>
						{!isFavourite
							? (
								<button className='Row__poster-info--icon icon--favourite' onClick={handleAdd}>
									<FaPlus />
								</button>
							) : (
								<button className='Row__poster-info--icon icon--favourite' onClick={handleRemove}>
									<FaMinus />
								</button>
							)}
						<button className='Row__poster-info--icon icon--toggleModal'>
							<FaChevronDown onClick={handleModalOpening} />
						</button>
					</div>
					<div className="Row__poster-info--title">
						<h3>{code} - {title}</h3>
					</div>
					<div className="Row__poster-info--genres">
						{genres && genres.map(genre => (
							<span key={`Genre--id_${genre}`} className="genre-title">{genre}</span>
						))}
					</div>
				</div> */}
			</div>
		</>
	);
};

export default RowPoster2;