/* eslint-disable */
import "./navbar.scss";
import { useState, useRef, Fragment, useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import useScroll from "../../hooks/useScroll";
import useOutsideClick from "../../hooks/useOutsideClick";
import { motion } from "framer-motion";
import { navbarFadeInVariants } from "../../motionUtils";
import { LOGO_URL, MOBILE_LOGO_URL, PROFILE_PIC_URL } from "../../requests";
import { FaCaretDown } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/auth/auth.selectors";
import { signOutStart } from "../../redux/auth/auth.actions";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DropdownLanguage from "../DropdownLanguage/DropdownLanguage";
import i18n from 'i18next';

import { useAuthen } from "hooks/useAuthen";
import { useSysMenu } from "hooks/useSysMenu";

import profileImage from 'assets/images/profile.jpg';
import profileImageNon from 'assets/images/profile-non.png';
import ChangePassword from "components/ChangePassword";

import { useUserInfo } from "hooks/useUserInfo";

const { REACT_APP_ENV } = process.env;


const loggedMenu = [
	{
		id: 1,
		name: 'Profile',
	},
	{
		id: 2,
		name: 'Settings'
	},
	{
		id: 3,
		name: 'SignOut'
	}
]

const noLoggedMenu = [
	{
		id: 1,
		menuName: 'SignIn',
		to: '/login'
	},
	{
		id: 2,
		menuName: 'Register',
		to: '/login'
	}
]

const menus = noLoggedMenu;




const Navbar = () => {
	const { t } = useTranslation();
	let history = useHistory();
	const { width } = useViewport();
	const isScrolled = useScroll(70);
	const [genresNav, setGenresNav] = useState(false);
	const [profileNav, setProfileNav] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [isShow, setIsShow] = useState(true);

	const genresNavRef = useRef();
	const profileNavRef = useRef();
	const currentUser = useSelector(selectCurrentUser);

	const userActions = useAuthen();
	const auth = userActions.auth;
	const userInfo = useUserInfo();

	const sysMenu = useSysMenu();

	const dispatch = useDispatch();

	useOutsideClick(genresNavRef, () => {
		if (genresNav) setGenresNav(false);
	});
	useOutsideClick(profileNavRef, () => {
		if (profileNav) setProfileNav(false);
	});


	useEffect(() => {
		userInfo.onGetUserInfo();
	}, [])


	return (
		<>

			{!isShow ? (<></>) : (


				<motion.nav
					className={`Navbar ${isScrolled && "Navbar__fixed"}`}
					variants={navbarFadeInVariants}
					initial="hidden"
					animate="visible"
					exit="hidden"
				>
					<Link to="/">
						<img className="Navbar__logo" src={width >= 600 ? LOGO_URL : MOBILE_LOGO_URL} alt="" />
					</Link>
					{width >= 1024 ? (
						<ul className="Navbar__primarynav Navbar__navlinks">
							{sysMenu?.menus && sysMenu?.menus?.filter(({ isPrivate }) => isPrivate == false).map(m => (
								<li key={m.id} className="Navbar__navlinks--link">
									<NavLink to={m.linkTo} activeClassName="activeNavLink">
										{i18n.language == "en" ? m.sysMenuName : m.sysMenuNameLocale}
									</NavLink>
								</li>
							))}
							{auth && (
								<>
									{sysMenu?.menus && sysMenu?.menus?.filter(({ isPrivate }) => isPrivate == true).map(m => (
										<li key={m.id} className="Navbar__navlinks--link">
											<NavLink to={m.linkTo} activeClassName="activeNavLink">
												{i18n.language == "en" ? m.sysMenuName : m.sysMenuNameLocale}
											</NavLink>
										</li>
									))}
								</>
							)}
						</ul>
					) : (
						<div
							className={`Navbar__primarynav Navbar__navlinks ${isScrolled && "Navbar__primarynav--scrolled"}`}
							onClick={() => setGenresNav(!genresNav)}
						>
							<span className="Navbar__navlinks--link">เมนู</span>
							<FaCaretDown className="Navbar__primarynav--toggler Navbar__primarynav--caret" />
							<div
								className={`Navbar__primarynav--content ${genresNav ? "active" : ""}`}
							>
								{genresNav && (



									<ul
										className="Navbar__primarynav--content-wrp"
										ref={genresNavRef}
									>
										{sysMenu?.menus && sysMenu?.menus?.filter(({ isPrivate }) => isPrivate == false).map(m => (
											<li key={m.id} className="Navbar__navlinks--link">
												<NavLink to={m.linkTo} activeClassName="activeNavLink">
													{i18n.language == "en" ? m.sysMenuName : m.sysMenuNameLocale}
												</NavLink>
											</li>
										))}


										{auth && (
											<>
												{sysMenu?.menus && sysMenu?.menus?.filter(({ isPrivate }) => isPrivate == true).map(m => (
													<li key={m.id} className="Navbar__navlinks--link">
														<NavLink to={m.linkTo} activeClassName="activeNavLink">
															{i18n.language == "en" ? m.sysMenuName : m.sysMenuNameLocale}
														</NavLink>
													</li>
												))}
											</>)}
									</ul>
								)}
							</div>
						</div>
					)}
					<div className="Navbar__secondarynav">
						<div className="Navbar__navitem">
							<Searchbar />
						</div>
						<div className="Navbar__navitem">
							<li className="Navbar__navlinks--link">


								{/* {!auth &&
								<NavLink to="/login" activeClassName="activeNavLink">
									{t('button_register_label')} / {t('button_login_label')}
								</NavLink>
							} */}
								{/* {auth &&
								<li className="Navbar__navlinks--link"
									style={{ cursor: 'pointer' }}
									onClick={() =>
										userActions.logout()
										//dispatch(signOutStart())
									}
								>
									{t('button_logout_label')} ( {auth?.userInfo?.firstName} {auth?.userInfo?.lastName} )
								</li>
							} */}
								<li className="Navbar__navlinks--link"
									style={{ cursor: 'pointer' }}
								>
									<DropdownLanguage />
								</li>

							</li>
						</div>
						<div className="Navbar__navitem">

							<div
								className={`Navbar__navprofile ${profileNav && "active"}`}
								onClick={() => setProfileNav(!profileNav)}
							>

								<img
									style={{ borderRadius: 20 }}
									className="Navbar__navprofile--avatar Navbar__navprofile--toggler"
									// src={auth ? profileImage : profileImageNon}
									src={profileImageNon}
									alt="Profile Picture"
								/>
								{auth && userInfo?.datas?.firstName} {auth && userInfo?.datas?.lastName}
								<FaCaretDown className="Navbar__navprofile--toggler Navbar__navprofile--caret" />
								<div className={`Navbar__navprofile--content ${profileNav ? "active" : ""}`}>

									{profileNav && (
										<ul
											className="Navbar__navprofile--content-wrp"
											ref={profileNavRef}
										>
											{auth &&
												(
													<Fragment>

														{/* {menus.map(m => (
														<li className="Navbar__navlinks--link"
															onClick={() => history.push(m.to)}
														>
															{m.menuName}
														</li>
													))} */}

														<li className="Navbar__navlinks--link" onClick={() => history.push('/user-profile')}>
															{i18n.language == 'en' ? `Profile` : `ข้อมูลผู้ใช้งาน`}
														</li>
														<li className="Navbar__navlinks--link" onClick={() => setIsModal(true)}>
															{i18n.language == 'en' ? `Change Password` : `เปลี่ยนรหัสผ่าน`}

														</li>

														{/* <li
														className="Navbar__navlinks--link"

													>
														Settings
													</li>

													<li
														className="Navbar__navlinks--link"
														onClick={() => dispatch(signOutStart())}
													>
														Sign Out
													</li> */}
													</Fragment>
												)}
											{!auth &&
												(
													<Fragment>

														<li
															className="Navbar__navlinks--link"
															onClick={() => history.push('/login')}
														>

															{i18n.language == 'en' ? `Sign In` : `เข้าสู่ระบบ`}
														</li>
													</Fragment>
												)}

											{auth &&
												(
													<Fragment>

														<li
															className="Navbar__navlinks--link"
															onClick={() => userActions.logout()}
														>
															{i18n.language == 'en' ? `Sign Out` : `ออกจากระบบ`}

														</li>
													</Fragment>
												)}


										</ul>
									)}
								</div>
							</div>
						</div>
					</div>
					<ChangePassword isModal={isModal} onToggle={setIsModal} />
				</motion.nav>

			)}

		</>
	);
};

export default Navbar;
