/* eslint-disable */
import "./homepage.scss";
import { useState, useEffect, useMemo } from "react";
import Banner from "../../components/Banner/Banner";
//import Row from "../../components/Row/Row";
import Row2 from "../../components/Row/Row2";
import Credits from "../../components/Credits/Credits";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useCourse } from "hooks/useCourse";
import i18n from 'i18next';

import {
  Spinner, Row, Col, Container
} from "reactstrap";

const CategoryPage = () => {
  let { categoryId } = useParams();
  const courses = useCourse();
  let allCourse;
  let dataRow;

  // for banner


  const location = useLocation()
  useEffect(() => {

    console.log('category-', location.pathname)
    if (categoryId == "learning") {
      courses.getCategoriesLearning(categoryId)
    } else if (categoryId == "passed") {
      courses.getCategoriesPassed(categoryId)
    } else
      courses.getCategories(categoryId)

    courses.getBanner();

  }, [location.pathname])



  return (
    <Container fluid >
      {courses.loading &&
        <Row className="mt-500">
          <Col sm="1" className='container center'>
            <Spinner animation="border" variant="primary" className="mt-5" />
          </Col>
        </Row>
      }

      {!courses?.loading && courses?.categories &&
        <motion.div
          className="Homepage"
          variants={defaultPageFadeInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Banner banners={courses?.banners} />
          {courses?.categories?.map((cat, idx) => {
            if (cat.items.length > 0)
              return (
                <Row2 key={cat.productCategoryId}
                  datas={cat.items} title={i18n.language == "en" ? cat.productCategoryName : cat.productCategoryNameLocale} genre={""} isLarge={cat.isLarge}
                  isLearning={categoryId == "learning" || categoryId == "passed"}
                />
              )
          })}



          <Credits />
        </motion.div>}
    </Container>
  );
};

export default CategoryPage;
