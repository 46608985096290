/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Card,
    CardImg,
    CardBody,
    CardText,
    ButtonToggle,
} from "reactstrap";
import { useVerifyAccount } from "hooks/useVerifyAccount";
import InfoCard from './InfoCard';
import { useCheckMail } from 'hooks/useCheckMail';
import ChangePassword from 'components/ChangePassword';

const VerifyAccount = () => {
    const { verifyCode } = useParams();
    const history = useHistory();
    const verifyAccount = useVerifyAccount();
    const pCheckmail = useCheckMail();

    // useEffect(() => {

    //     console.log(' verify - code')

    //     if (verifyCode != null && verifyCode != "")
    //         verifyAccount.onVerifyAccount(verifyCode);
    // }, [verifyCode])


    useEffect(() => {
        //  if (verifyAccount?.datas == true)
        //     history.push('/login');
        console.log('check mail data:')
        console.log(pCheckmail?.data)
    }, [pCheckmail?.data])

    return (
        <Container fluid className="Course pt-5 text-dark" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Row>
                <Col xl="12" >
                    <InfoCard
                        title={pCheckmail?.data?.title}
                        message={pCheckmail?.data?.message}
                        styles={{
                            position: 'absolute', left: '50%',
                            top: '50%'
                        }} />
                </Col>
            </Row>
            <ChangePassword />
        </Container>
    )
}

export default VerifyAccount;