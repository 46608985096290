/* eslint-disable */
import "./banner.scss";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants } from "../../motionUtils";
import { BASE_IMG_URL } from "../../requests";
import { FaPlay } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { randomize, truncate } from "_helpers/utils";
import { Link } from "react-router-dom";
import SkeletonBanner from "../SkeletonBanner/SkeletonBanner";
import { useDispatch, useSelector } from "react-redux";
import { showModalDetail } from "../../redux/modal/modal.actions";
import { selectTrendingMovies, selectNetflixMovies } from "../../redux/movies/movies.selectors";
import { selectNetflixSeries } from "../../redux/series/series.selectors";
import LazyBackground from './LazyBackground';
import defaultBanner from '.././../assets/images/banners/banner.jpg';
import { apiBaseURL } from '_helpers';
import i18n from 'i18next';

import {
	useHistory
} from "react-router-dom";

const Banner = ({ type, banners }) => {
	let selector;
	switch (type) {
		case "movies":
			selector = selectTrendingMovies;
			break;
		case "series":
			selector = selectNetflixSeries;
			break;
		default:
			selector = selectNetflixMovies;
			break;
	}

	const router = useHistory();
	const myData = useSelector(selector);
	const { loading, error, data: results } = myData;
	const [bannerImage, setBannerImage] = useState(defaultBanner)
	const finalData = results[randomize(results)];
	const fallbackTitle = finalData?.title || finalData?.name || finalData?.original_name;
	const description = truncate(finalData?.overview, 150);
	const dispatch = useDispatch();

	const handlePlayAnimation = event => {
		event.stopPropagation();
	};

	const handleModalOpening = () => {
		dispatch(showModalDetail({ ...finalData, fallbackTitle }));
	}

	useEffect(() => {
		const imgUrl = `${apiBaseURL}/images/thumbor-resize/7e0f166a-1fe8-4dd2-bcd3-b3ecafdbe49b/1744/728`;//"https://mylearn-api.devz.sbs/api/images/banners/1920/1080";
		const img = new Image(1920, 1080)
		img.src = imgUrl;
		img.onload = () => setBannerImage(imgUrl)
	}, [type])


	useEffect(() => {
		console.log('banners data');
		console.log(banners)
		if (banners.length > 0)
			if (banners[0]?.posterImageUrl != "") {
				const imgUrl = banners[0]?.posterImageUrl;
				const img = new Image(1920, 1080)
				img.src = imgUrl;
				img.onload = () =>
					setBannerImage(imgUrl)
				//setBannerImage(banners[0]?.posterImageUrl)
			}

	}, [banners])

	return (
		<>
			<motion.section
				variants={bannerFadeInLoadSectionVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner__loadsection"
			>
				{loading && <SkeletonBanner />}
				{error && <div className="errored">Oops, an error occurred.</div>}
			</motion.section>



			<motion.header
				variants={bannerFadeInVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner"
				// style={{ backgroundImage: `url(${BASE_IMG_URL}/${finalData?.backdrop_path})` }}
				style={{ backgroundImage: `url(${bannerImage})` }}
			>




				<motion.div
					className="Banner__content"
					variants={staggerOne}
					initial='initial'
					animate='animate'
					exit='exit'
				>
					{/* <motion.h1 variants={bannerFadeInUpVariants} className="Banner__content--title">{banners[0]?.courseCode} - {banners[0]?.courseNameLocale}</motion.h1> */}
					<motion.div variants={bannerFadeInUpVariants} className="Banner__buttons">

						<button
							disabled={false}
							className="Banner__button"
							onClick={() => { router.push(`/course/${banners[0]?.id}`); }}
						>
							<FaPlay />
							<span>{i18n.language == "en" ? `register / learn ` : `ลงทะเบียน / เรียน`} </span>
						</button>
						{/* <button
								disabled={true}
								className="Banner__button"
								onClick={handleModalOpening}
							>
								<BiInfoCircle size="1.5em" />
								<span>รายละเอียด</span>
							</button> */}
					</motion.div>
					{/* <motion.p variants={bannerFadeInUpVariants} className="Banner__content--description">{description}</motion.p> */}
				</motion.div>
				<div className="Banner__panel" />
				<div className="Banner__bottom-shadow" />
			</motion.header>

		</>
	)
}

export default React.memo(Banner);