/* eslint-disable */
import { any } from "is_js";
import { useEffect, useMemo, useState } from "react";
import {
    useSetRecoilState,
    useRecoilValue,
    useRecoilState,
    atom,
} from "recoil";
import { history, useFetchWrapper, apiBaseURL } from "_helpers";

export { useApi };
//const baseApi = "positions";
const waitingTime = 500;
const defaultPerPage = 25;
const defaultPageIndex = 1;

export const datasAtom = atom({
    key: `apiDatasAtom`,
    default: []
    //[{ testkey1: 111 }, { testkey2: 222 }]
});

export const dataAtom = (id) => atom({
    key: `apiData${id}`,
    default: {},
});


export const useData = (id) => {
    return useRecoilValue(dataAtom(id));
};

export const useSetData = (id) => {
    return useSetRecoilState(dataAtom(id));
};


function useApi() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [datas, setDatas] = useRecoilState(datasAtom);
    const [updating, setUpdating] = useState(false);
    return {
        // data
        onUpdate, onGetData, onSetData, onGet, onPost,
        updating
    };

    async function onSetData(_key, _data) {
        return setApiData(_key, _data)
    }


    async function setApiData(_key, _data) {
        const resp = datas.find(item => {
            return Object.keys(item).find(function (key) {
                if (key == _key) {
                    item[key] = _data;
                    return item[key];
                }
            });
        });

        if (resp == undefined) {
            datas.push({ [_key]: _data });
        }
    }



    async function getApiData(masterKey) {
        // find and return  read only
        const resp = datas.find(data => {
            return Object.keys(data).find(function (key) {
                //console.log(key)
                if (key == masterKey) //console.log(data[key])
                    return data[key]
                // else return {};


            });
        });
        return resp[masterKey];
    }


    async function updateByApi(req) {
        setUpdating(true);
        return fetchWrapper
            .put(`${baseUrl}/${req.baseApi}`, req.data)
            .then(async (response) => {

                // update recoil data
                let mydata = await getApiData(req.masterKey);
                mydata.map(itm => {
                    if (itm.id == req.aKey) {
                        itm[req.fieldName] = req.data[req.fieldName];
                        //console.log(itm[req.fieldName]);
                        //console.log(req.data[req.fieldName])
                    }
                });
                setTimeout(() => {
                    setUpdating(false);

                    if (req.onSuccess)
                        req.onSuccess();
                }, waitingTime);
            });
    }

    async function getByApi(req) {
        setUpdating(true);
        return fetchWrapper
            .get(`${baseUrl}/${req.baseApi}`)
            .then(async (response) => {

                // update recoil data
                let mydata = await getApiData(req.masterKey);
                mydata.map(itm => {
                    if (itm.id == req.aKey) {
                        itm[req.fieldName] = response//req.data[req.fieldName];
                        //console.log(itm[req.fieldName]);
                        //console.log(req.data[req.fieldName])
                    }
                });
                setTimeout(() => {
                    setUpdating(false);

                    if (req.onSuccess)
                        req.onSuccess(response);
                }, waitingTime);
            });
    }

    async function postByApi(req) {
        setUpdating(true);
        return fetchWrapper
            .post(`${baseUrl}/${req.baseApi}`, req.data)
            .then(async (response) => {

                // update recoil data
                let mydata = await getApiData(req.masterKey);
                mydata.map(itm => {
                    if (itm.id == req.aKey) {
                        itm[req.fieldName] = response//req.data[req.fieldName];
                        //console.log(itm[req.fieldName]);
                        //console.log(req.data[req.fieldName])
                    }
                });
                setTimeout(() => {
                    setUpdating(false);

                    if (req.onSuccess)
                        req.onSuccess(response);
                }, waitingTime);
            });
    }




    async function onUpdate(req) {
        updateByApi(req)
    }

    async function onPost(req) {
        postByApi(req)
    }

    async function onGet(req) {
        return getByApi(req)
    }

    async function onGetData(id) {
        return await getApiData(id);
    }

}
