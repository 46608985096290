/* eslint-disable */
import './signUp.scss';
import { useState, useEffect } from 'react';
import InputField from "../InputField/InputField";
import Loader from "../Loader/Loader";
import { motion } from "framer-motion";
import { authFadeInUpVariants, staggerOne } from "../../motionUtils";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signUpStart } from "../../redux/auth/auth.actions";
import { selectAuthLoadingState } from "../../redux/auth/auth.selectors";
//import { useUserActions } from '_actions';
import { Alert } from 'reactstrap';
import { useParams, useHistory } from "react-router-dom";

import { useAuthen } from 'hooks/useAuthen';
import { useCheckMail } from 'hooks/useCheckMail';

const SignUp = () => {
	const history = useHistory();
	const pCheckMail = useCheckMail();

	const [isError, setIsError] = useState();
	const [errMessage, setErrMessage] = useState();

	const dispatch = useDispatch();
	const userActions = useAuthen();
	const isLoading = useSelector(selectAuthLoadingState);
	const { register, handleSubmit, errors, getValues, setError } = useForm({
		mode: "onTouched"
	})

	// const onSubmit = data => {
	// 	const { displayName, email, password } = data;
	// 	dispatch(signUpStart({ displayName, email, password }));
	// }

	const onSubmit = data => {

		console.log(data);

		//history.push('/please-check-email')
		const { email, password } = data;
		const response = userActions.register(data)
			.then(response => {
				pCheckMail.onCheckMailData({ title: `Please check your email!`, message: `An email has been sent to ${email}. Please click on the included link to virify your account.` })

				setTimeout(() => {
					history.push('/please-check-email')
				}, 500);
			})
			.catch(error => {
				setIsError(true)
				setErrMessage(error.response?.data?.errors);
			});
	}


	useEffect(() => {
		console.log('err mesage')
		console.log(errMessage)
	}, [errMessage])


	return (
		<motion.form
			variants={staggerOne}
			initial="initial"
			animate="animate"
			exit="exit"
			className="SignUp__form"
			onSubmit={handleSubmit(onSubmit)}
		>
			<motion.div variants={authFadeInUpVariants} className="SignUp__form--inputwrp">
				<InputField
					type="text"
					name="firstName"
					placeholder="Firstname"
					validationMessage="Please enter your name."
					validation={register({
						required: true,
						minLength: 2,
						maxLength: 60
					})}
					errors={errors}
					disabled={isLoading}
				/>
			</motion.div>
			<motion.div variants={authFadeInUpVariants} className="SignUp__form--inputwrp">
				<InputField
					type="text"
					name="lastName"
					placeholder="LastName"
					validationMessage="Please enter your name."
					validation={register({
						required: true,
						minLength: 2,
						maxLength: 60
					})}
					errors={errors}
					disabled={isLoading}
				/>
			</motion.div>
			<motion.div variants={authFadeInUpVariants} className="SignUp__form--inputwrp">
				<InputField
					type="text"
					name="email"
					placeholder="E-mail"
					validationMessage="Please enter a valid email address."
					validation={register({
						required: true,
						pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
					})}
					errors={errors}
					disabled={isLoading}
				/>
			</motion.div>
			<motion.div variants={authFadeInUpVariants} className="SignUp__form--inputwrp">
				<InputField
					type="password"
					name="password"
					placeholder="Password"
					validationMessage="The password should have a length between 6 and 30 characters."
					validation={register({
						required: true,
						minLength: 6,
						maxLength: 30,
					})}
					errors={errors}
					disabled={isLoading}
				/>
			</motion.div>
			<motion.div variants={authFadeInUpVariants} className="SignUp__form--inputwrp">
				<InputField
					type="password"
					name="confirmPassword"
					placeholder="Confirm your password"
					validationMessage="Passwords should match"
					validation={register({
						validate: {
							matchesPreviousPassword: (value) => {
								const { password } = getValues();
								return value && password === value || "Passwords should match!";
							}
						}
					})}
					errors={errors}
					disabled={isLoading}
				/>

			</motion.div>

			<motion.div variants={authFadeInUpVariants} className="SignUp__form--inputwrp">

				{errMessage &&
					<Alert color="danger">
						{errMessage && errMessage.map(msg => (msg))}
					</Alert>
				}

			</motion.div>
			<motion.button
				type="submit"
				variants={authFadeInUpVariants}
				className={`SignUp__form--button button__submit ${isLoading && 'loading'}`}
				disabled={isLoading}
			>
				{isLoading ? <Loader /> : 'Sign Up'}
			</motion.button>
		</motion.form>
	)
}

export default SignUp;