export const getOneMonthAgoReleaseDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    let formattedDate = date.toJSON().slice(0, 10);

    return formattedDate;
}

export const dateToYearOnly = date => date.slice(0, 4);

export const capitalizeFirstLetter = text => (
    text.charAt(0).toUpperCase() + text.slice(1)
);

export const randomize = data => (
    Math.floor(Math.random() * data.length - 1)
);

export const truncate = (text, n) => (
    text?.length > n ? text.substr(0, n - 1) + "..." : text
);


export const numberFormatter = (number, fixed = 2) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(number)) >= 1.0e9
        ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(number)) >= 1.0e6
            ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(Number(number)) >= 1.0e3
                ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
                : Math.abs(Number(number)).toFixed(fixed);
};