/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useMyLessonMedias };

const myLessonMediasAtom = atom({
    key: 'myLessonMedias',
    default: []
});



function useMyLessonMedias() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [mycourses, setMyCourses] = useRecoilState(myLessonMediasAtom);



    useMemo(() => {
        //  getMyCourse();
    }, []);



    return {
        onUpdate, onLearningUpdate
    }

    async function onUpdate(myLessonMediaId, req) {
        // let req =
        // {
        //     criteria: {
        //         searchText: ""
        //     },
        //     pageIndex: 0,
        //     pageSize: 24
        // }

        ///api/my-lessons/{courseId}/{lessonId}
        return fetchWrapper.put(`${baseUrl}/my-lesson-medias/${myLessonMediaId}`, req)
            .then(response => {
                console.log(response)
                //setMyCourses(response?.data);
            });
    }


    async function onLearningUpdate(courseId, lessonId, lessonMediaId, myLessonMediaId, req, onSuccess) {
        // let req =
        // {
        //     criteria: {
        //         searchText: ""
        //     },
        //     pageIndex: 0,
        //     pageSize: 24
        // }

        ///api/my-lessons/{courseId}/{lessonId}
        return fetchWrapper.post(`${baseUrl}/my-lesson-medias/${courseId}/${lessonId}/${lessonMediaId}/${myLessonMediaId}/learningUpdate`, req)
            .then(response => {
                onSuccess();
                console.log(response)
                //setMyCourses(response?.data);
            });
    }




}