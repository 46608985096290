import React from 'react';
import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import './index.scss';




const InfoCard = ({ title, message }) => {
    const history = useHistory();
    return (
        <Row >
            <Col sm="4" className='container center'>
                <Card body>
                    <CardTitle>{title}</CardTitle>
                    <CardText>{message}</CardText>
                    <Button color='primary'
                        onClick={() => { history.push('/login') }}

                    >Go to Login</Button>
                </Card>
            </Col>
            {/* <Col sm="6">
                <Card body>
                    <CardTitle>Special Title Treatment</CardTitle>
                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                    <Button>Go somewhere</Button>
                </Card>
            </Col> */}
        </Row>
        //     <Row>
        //         <Col sm="6">
        //             <Card body>
        //                 <CardTitle>Special Title Treatment</CardTitle>
        //                 <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        //                 <Button>Go somewhere</Button>
        //             </Card>
        //         </Col>
        //         <Col sm="6">
        //             <Card body>
        //                 <CardTitle>Special Title Treatment</CardTitle>
        //                 <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        //                 <Button>Go somewhere</Button>
        //             </Card>
        //         </Col>
        //     </Row>
    );
};

export default InfoCard;