/* eslint-disable */
import { Container } from 'reactstrap';

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar/Navbar"
import Homepage from "./pages/Homepage/Homepage"
import Course from "./pages/Course/Course"
import VerifyAccount from 'pages/VerifyAccount';
import ResetPassword from 'pages/ResetPassword';
import ResetNewPassword from 'pages/ResetNewPassword';
import PleaseCheckEmail from 'pages/PleaseCheckEmail';
import Movies from "./pages/Movies/Movies"
import TVSeries from './pages/TVSeries/TVSeries';
import Popular from "./pages/Popular/Popular";
import MyList from './pages/MyList/MyList';
import Auth from "./pages/Auth/Auth";
import Search from "./pages/Search/Search";
import Category from "./pages/Category/Category";
import DetailModal from "./components/DetailModal/DetailModal";
import PlayModal from "./components/PlayModal/PlayModal";
import ExamModal from "./components/ExamModal/ExamModal";
import CertificateModal from 'components/CertificateModal';
import Certificate from 'pages/Certificate';

import SplashAnimation from "./components/SplashAnimation/SplashAnimation";
import PlayAnimation from "./components/PlayAnimation/PlayAnimation";
import { selectCurrentUser } from './redux/auth/auth.selectors';
import { selectSearchResults } from "./redux/search/search.selectors";
import { checkUserSession } from "./redux/auth/auth.actions";

import { useAuthen } from 'hooks/useAuthen';
import CategoryPage from 'pages/Categories/CategoryPage';
import UserProfile from 'pages/UserProfile';




const { REACT_APP_ENV } = process.env;


if (REACT_APP_ENV === 'staging' || REACT_APP_ENV === 'production') {
    console.log = function () { };
}

const App = () => {

    const currentUser = useSelector(selectCurrentUser);
    const searchResults = useSelector(selectSearchResults);
    const dispatch = useDispatch();
    const location = useLocation();
    const authen = useAuthen();

    const auth = authen.auth;

    useEffect(() => {
        dispatch(checkUserSession());
    }, [dispatch])

    return (



        <Container fluid className="p-0">
            {(  // currentUser &&
                <>
                    <Navbar />
                    <DetailModal />
                    <PlayModal />
                    <ExamModal />
                    <CertificateModal />
                </>
            )}
            <AnimatePresence exitBeforeEnter>

                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/"
                        component={Homepage}
                    />
                    {/* <Redirect to="/home" /> */}

                    <Route
                        path="/splash"
                        component={SplashAnimation}
                    />
                    <Route
                        path="/play"
                        component={PlayAnimation}
                    />
                    <Route
                        path="/search"
                        render={() => currentUser
                            ? (searchResults && <Search results={searchResults} />)
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/home"
                        component={Homepage}
                    //render={() => <Homepage />}
                    />
                    <Route
                        exact
                        path="/course"
                        component={Course}
                    // render={() => <Course />}
                    />

                    <Route
                        exact
                        path="/verify-account/:verifyCode"
                        component={VerifyAccount}
                    //render={() => <VerifyAccount />}
                    />

                    <Route
                        exact
                        path="/certificate/:mycourseId/:myCourseOwnerBy"
                        component={Certificate}
                    //render={() => <VerifyAccount />}
                    />




                    <Route
                        exact
                        path="/forgot-password"
                        component={ResetPassword}
                    // render={() => <ResetPassword />}
                    />



                    <Route
                        exact
                        path="/reset-newpassword/:resetCode"
                        component={ResetNewPassword}
                    // render={() => <ResetNewPassword />}
                    />

                    <Route
                        exact
                        path="/please-check-email"
                        component={PleaseCheckEmail}
                    // render={() => <PleaseCheckEmail />}
                    />


                    <Route
                        exact
                        path="/course/:courseId"
                        component={Course}
                    //  render={() => <Course />}
                    />

                    {/* <Route
                        exact
                        path="/browse/:categoryId"
                        render={() => currentUser
                            ? <Homepage />
                            : <Redirect to="/login" />}
                    /> */}
                    {/* <Route
                        exact
                        path="/browse/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/login" />}
                    /> */}
                    <Route
                        exact
                        path="/tvseries"
                        render={() => currentUser ? <TVSeries /> : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/categories/:categoryId"
                        component={CategoryPage}
                    // render={() => <CategoryPage />}
                    />

                    <Route
                        exact
                        path="/user-profile"
                        component={UserProfile}
                    //render={() => <UserProfile />}
                    />

                    <Route
                        exact
                        path="/tvseries/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/movies"
                        render={() => currentUser ? <Movies /> : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/movies/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/popular"
                        render={() => currentUser ? <Popular /> : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/popular/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/mylist"
                        render={() => currentUser ? <MyList /> : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/login"
                        render={() => auth ? <Redirect to="/home" /> : <Auth />}
                    />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>

            </AnimatePresence>
        </Container>
    )
}

export default App;
