/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  //   CardTitle,
  //   CardText,
  Row,
  Col,
  Table,
  CardImg,
} from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { showModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch } from "react-redux";
//import { Link, NavLink } from "react-router-dom";
import i18n from "i18next";
import "./style.scss";
import "./lessonButton.scss"
import { usePlayVideo } from "hooks/usePlayVideo";
import { useExamModal } from "hooks/useExamModal";

import { useAuthen } from "hooks/useAuthen";
import { useHistory } from "react-router-dom";
import { FaPlay, FaCheckDouble, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageRenderer from 'components/ImageRenderer/ImageRenderer';

import LessonBox from "./LessonBox";
import Sample from "components/CourseDetail/CourseAndExam/sam";
import CourseAndExam from "components/CourseDetail/CourseAndExam";
import ExamSummary from "components/CourseDetail/ExamSummary";
import CourseInfoCard from "components/CourseInfoCard";


export const generateNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const CourseDetail = (props) => {
  const playerActions = usePlayVideo();
  const examModal = useExamModal();
  const userActions = useAuthen();
  const history = useHistory();

  const { course, isRegister, onRefreshCourse, onCompleteCourse } = props;
  const [activeTab, setActiveTab] = useState(isRegister ? "2" : "1");
  const [bannerImage, setBannerImage] = useState()
  const { t } = useTranslation();
  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
  const dispatch = useDispatch();


  const onError = (ev) => {
    //setDefaultUrl(defaultBanner);
    const baseUrl = "https://mylearn-api.devz.sbs/api";
    const ran = generateNumber(1, 10000);
    const imgUrl = baseUrl + "/images/banners/259/146?ran=" + ran;
    setBannerImage(imgUrl);
    //console.log("load imag  error !!!!...");
    ev.target.src = imgUrl;

  };


  const handlePlayModalOpening = () => {
    // let datas = {
    //   url: _url,
    //   datas: {}
    // };
    // playerActions.setPlay(datas);
  };

  const tabs = [
    {
      tabId: "1",
      tanName: t("couse_description_label"),
    },
    {
      tabId: "2",
      tanName: t("course_lesson_and_test_label"),
    },
    {
      tabId: "3",
      tanName: 'ผลทดสอบ',
    },
    {
      tabId: "4",
      tanName: t("course_teacher_label"),
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  useMemo(() => {
    const baseUrl = "https://mylearn-api.devz.sbs/api";
    const ran = generateNumber(1, 10000);
    const imgUrl = baseUrl + "/images/banners/259/146?ran=" + ran;
    setBannerImage(imgUrl);

  }, [])


  useEffect(() => {
    setActiveTab(isRegister ? "2" : "1")
  }, [isRegister])

  //console.log("CourseDetail >>", props);
  return (
    <div className="body-text">
      {/* {course && `${course?.courseCode} - ${course && course?.courseName}`} */}
      <CourseInfoCard course={course} />
      <Nav tabs>
        {tabs.map((tab) => {

          if (tab.tabId == "3") {
            if (course?.isLearningComplete) return (
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab == tab.tabId },
                    "nav_link"
                  )}
                  onClick={() => {
                    toggle(tab.tabId);
                  }}
                >
                  {tab.tanName}
                </NavLink>
              </NavItem>
            )
          } else
            return (
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab == tab.tabId },
                    "nav_link"
                  )}
                  onClick={() => {
                    toggle(tab.tabId);
                  }}
                >
                  {tab.tanName}
                </NavLink>
              </NavItem>
            )
        })}

        {/*
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" }, 'nav_link')}
            onClick={() => {
              toggle("1");
            }}
          >
            {t('couse_description_label')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" }, 'nav_link')}
            onClick={() => {
              toggle("2");
            }}
          >
            {t('course_lesson_and_test_label')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" }, 'nav_link')}
            onClick={() => {
              toggle("3");
            }}
          >
            {t('course_teacher_label')}
          </NavLink>
        </NavItem> */}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="p-3">
          <Row>
            <Col sm="12">
              {course?.courseContents?.map((itm) => (
                <p className="text-black">
                  <h3 className="">
                    {/* {i18n.language == "en"
                      ? itm.contentName
                      : itm.contentNameLocale} */}
                    <div dangerouslySetInnerHTML={{ __html: i18n.language == "en" ? itm.contentName : itm.contentNameLocale }}></div>
                  </h3>
                  {/* {i18n.language == "en"? itm.contentDescription: itm.contentDescriptionLocale} */}

                  <div dangerouslySetInnerHTML={{ __html: i18n.language == "en" ? itm.contentDescription : itm.contentDescriptionLocale }}></div>
                </p>
              ))}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2" className="p-3">
          <Row>
            <Col xl="12">
              <CourseAndExam course={course} isRegister={isRegister} onRefreshCourse={onRefreshCourse} onCompleteCourse={onCompleteCourse} />
              {/* <CourseAndExam course={course} isRegister={isRegister} /> */}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3" className="p-3">
          <Row>
            <Col xl="12">
              {isRegister && course?.isLearningComplete &&
                <ExamSummary course={course} isRegister={isRegister} />}
              {/* <CourseAndExam course={course} isRegister={isRegister} /> */}
            </Col>
          </Row>
        </TabPane>
        {/* <TabPane tabId="3" className="p-3">
          <Row>

            <Col sm="12">
              <LessonBox />
            </Col>
          </Row>
        </TabPane> */}
        <TabPane tabId="4" className="p-3">
          <Row>
            <Col sm="3">
              {/* <Card className="border-0">
                <ImageRenderer
                  key={1}
                  url={bannerImage}
                  thumb={'https://i.imgur.com/67r0FdBm.jpg'}
                  width={1920}
                  height={1080}
                  onError={onError}
                />

              </Card> */}
            </Col>
            <Col sm="12">
              <p className="text-black">
                <h3>{course?.teacher?.teacherName}</h3>
                {course?.teacher?.teacherDescription}
              </p>
              <p className="text-black">
                <h4 className="">{i18n.language == 'en' ? 'Courses Offered' : 'หลักสูตรที่สอน'}</h4>
                {course?.teacher?.courses.map((c) => (
                  <>
                    {c.courseName}
                    <br />
                  </>
                ))}
              </p>
              <p className="text-black">
                <h4 className="">{i18n.language == 'en' ? 'Contact' : 'ข้อมูลการติดต่อ'}</h4>

                <Table>
                  <tbody>
                    <tr>
                      <th scope="row" className="text-black">
                        {i18n.language == 'en' ? 'Email' : 'อีเมล์'}
                      </th>
                      <td className="text-black">
                        {course?.teacher?.contract?.email}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-black">
                        {i18n.language == 'en' ? 'Phone' : 'เบอร์โทร'}
                      </th>
                      <td className="text-black">
                        {course?.teacher?.contract?.phone}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-black">
                        {i18n.language == 'en' ? 'Mobile' : 'มือถือ'}

                      </th>
                      <td className="text-black">
                        {course?.teacher?.contract?.mobile}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </p>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CourseDetail;
