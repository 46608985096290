/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, Input, Spinner, Alert } from 'reactstrap';
import { useHistory } from "react-router-dom";
import './index.scss';
import { useUserInfo } from 'hooks/useUserInfo';

const InfoCard = ({ title, message, resetCode, onResetNewPassword, isLoading, setIsLoading, errors }) => {

    const userInfo = useUserInfo();
    const [newPassword, setNewPassword] = React.useState();
    const [confirmPassword, setConfirmPassword] = React.useState([]);
    const [errorMessage, setErrorMessage] = useState();

    const history = useHistory();
    return (
        <Row >
            <Col sm="4" className='container center'>
                <Card body>
                    <CardTitle>{title}</CardTitle>
                    <CardText>{message}</CardText>
                    {/* <CardText>error: {errors}</CardText> */}
                    <Input onChange={(e) => setNewPassword(e.target.value)} className="mt-2" />
                    <Input onChange={(e) => setConfirmPassword(e.target.value)} className="mt-2" />
                    {isLoading &&
                        <Col className='container center'>
                            <Spinner animation="border" variant="primary" className="mt-3" />
                        </Col>}
                    {!isLoading &&
                        <Button color='primary' className="mt-2"
                            onClick={() => {

                                let req =
                                {
                                    email: userInfo?.datas?.email,
                                    password: newPassword,
                                    confirmPassword: confirmPassword,
                                    resetCode: resetCode

                                }

                                onResetNewPassword(req)
                                    .catch((error) => {
                                        setErrorMessage(error.response?.data?.errors);
                                        setIsLoading(false);
                                    });
                            }}

                        >Confirm reset password</Button>}

                    {errorMessage &&
                        <Alert color="danger" className='mt-2'>
                            {Object.keys(errorMessage).map((keys, index) => (
                                <li key={index}>{errorMessage[keys][0]}</li>
                            ))}
                        </Alert>
                    }
                </Card>
            </Col>
            {/* <Col sm="6">
                <Card body>
                    <CardTitle>Special Title Treatment</CardTitle>
                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                    <Button>Go somewhere</Button>
                </Card>
            </Col> */}
        </Row>
        //     <Row>
        //         <Col sm="6">
        //             <Card body>
        //                 <CardTitle>Special Title Treatment</CardTitle>
        //                 <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        //                 <Button>Go somewhere</Button>
        //             </Card>
        //         </Col>
        //         <Col sm="6">
        //             <Card body>
        //                 <CardTitle>Special Title Treatment</CardTitle>
        //                 <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        //                 <Button>Go somewhere</Button>
        //             </Card>
        //         </Col>
        //     </Row>
    );
};

export default InfoCard;