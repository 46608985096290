import { modalPlayActionTypes } from "./modal.types"

const initialState = {
    modalPlayIsClosed: true,
    modalPlayContent: {},
}

const modalPlayReducer = (state = initialState, action) => {
    switch (action.type) {


        case modalPlayActionTypes.SHOW_MODAL_PLAY:
            return {
                ...state,
                modalPlayIsClosed: false,
                modalIsClosed: true,
                modalPlayContent: { ...action.payload }
            }
        case modalPlayActionTypes.HIDE_MODAL_PLAY:
            return {
                ...state,
                modalPlayIsClosed: true,
                modalPlayContent: {}
            }

        default:
            return state
    }
}

export default modalPlayReducer
