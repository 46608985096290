/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';
import TokenService from "_helpers/token.service";

export { useCheckMail };

const dataAtom = atom({
    key: 'checkMailData',
    default: {}
});


function useCheckMail() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    const [data, setData] = useRecoilState(dataAtom);


    return {
        onCheckMailData,
        data
    }

    function onCheckMailData(data) {
        setData(data)
    }

}