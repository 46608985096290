/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useVerifyAccount };

const dataAtom = atom({
    key: 'verifyAccont',
    default: false
});


function useVerifyAccount() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [datas, setDatas] = useRecoilState(dataAtom);

    return {
        onVerifyAccount,
        datas,
    }
    async function onVerifyAccount(verifyCode) {
        return verifyAccont(verifyCode)
    }

    async function verifyAccont(verifyCode) {

        let req =
        {
            verifyCode: verifyCode
        }

        return fetchWrapper.post(`${baseUrl}/accounts/verify`, req)
            .then(response => {
                //console.log('verify - response ')
                //console.log(response)
                setDatas(response);
            });
    }



}