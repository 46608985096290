/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useUserInfo };

const dataAtom = atom({
    key: 'userInfomations',
    default: false
});


function useUserInfo() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [datas, setDatas] = useRecoilState(dataAtom);

    return {
        onGetUserInfo, getUserInfoById,
        datas
    }

    async function onGetUserInfo(req) {
        return getUserInfo(req)
    }


    async function getUserInfo(req) {
        return fetchWrapper.get(`${baseUrl}/accounts/info`, req)
            .then(response => {
                //console.log('verify - response ')
                //console.log(response)
                setDatas(response);
            });
    }

    async function getUserInfoById(userId) {
        return fetchWrapper.get(`${baseUrl}/accounts/info/` + userId)
            .then(response => {
                //console.log('verify - response ')
                //console.log(response)
                setDatas(response);
            });
    }

}