/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';


const menuData =
{
    data: [
        {
            id: "e370885e-7766-4773-b710-e024d944f476",
            seq: 1,
            parentId: "9cd92e8d-9b1a-4369-bad7-c1b9921b486b",
            systemCode: "FRONTEND",
            sysMenuCode: "LEARNER",
            sysMenuName: "Home",
            sysMenuNameLocale: "หน้าหลัก",
            isRoot: true,
            linkTo: "/home",
            isPrivate: false,
            coreSystemId: "00000000-0000-0000-0000-000000000000"
        },
        {
            id: "1e6a683f-ba8c-41f6-90fe-13c5a8d7cf4b",
            seq: 5,
            parentId: "57def9fb-43a0-452c-921c-5a496a9d0c80",
            systemCode: "FRONTEND",
            sysMenuCode: "LEARNER",
            sysMenuName: "Learning",
            sysMenuNameLocale: "กำลังเรียน",
            isRoot: true,
            linkTo: "/categories/learning",
            isPrivate: true,
            coreSystemId: "00000000-0000-0000-0000-000000000000"
        },
        {
            id: "61cc6e3f-7311-4538-92df-50d01acf35fc",
            seq: 6,
            parentId: "209e6d03-062f-4f86-8dbd-61b2cf3dfab1",
            systemCode: "FRONTEND",
            sysMenuCode: "LEARNER",
            sysMenuName: "Passed",
            sysMenuNameLocale: "ผ่านแล้ว",
            isRoot: true,
            linkTo: "/categories/passed",
            isPrivate: true,
            coreSystemId: "00000000-0000-0000-0000-000000000000"
        }
    ],
    totalRecord: 3
}

export { useSysMenu };

const systemMuenAtom = atom({
    key: 'systemMenus',
    default: []
});

function useSysMenu() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [menu, setMenu] = useRecoilState(systemMuenAtom);


    useMemo(() => {
        getMenu();
    }, []);



    return {
        getMenu,
        menus: menu,
    }

    function getMenu() {




        let req =
        {
            criteria: {
                searchText: "FRONTEND"
            },
            pageIndex: 0,
            pageSize: 0
        }



        return fetchWrapper.post(`${baseUrl}/sys-menus`, req)

            .then(response => {
                console.log(response?.data)
                setMenu(response?.data);
            });
    }



}