/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Card,
    CardImg,
    CardBody,
    CardText,
    ButtonToggle,
} from "reactstrap";
import './index.css';

import { useMyCourse } from 'hooks/useMyCourse';
import { useUserInfo } from 'hooks/useUserInfo';
import QRCode from "react-qr-code";
import { baseURL } from "_helpers";
import moment from 'moment';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';


const Certificate = ({ mycourseId, myCourseOwnerBy }) => {

    const printRef = React.useRef();

    const myCourse = useMyCourse();
    const userInfo = useUserInfo();


    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };

    useEffect(() => {
        myCourse.getMyCourseById(mycourseId);
        userInfo.getUserInfoById(myCourseOwnerBy);
    }, [mycourseId]);

    // useEffect(() => {
    //     console.log('------- 999 -------')
    //     console.log(myCourse?.mycourse);

    // }, [myCourse?.mycourse])

    return (
        <>
            {(myCourse?.mycourse && userInfo?.datas) &&
                <div class="certificate-container" ref={printRef}>
                    <div class="certificate">
                        {/* <div class="water-mark-overlay"></div> */}
                        <div class="certificate-header">
                            <img src="/learning-blue-logo.png" class="logo" alt="" />
                            {/* <img src="https://rnmastersreview.com/img/logo.png" class="logo" alt="" /> */}
                        </div>
                        <div class="certificate-body">

                            {/* <p class="certificate-title"><strong>MY LEARN eLEARNING INSTITUTE</strong></p> */}
                            <h1 style={{ color: 'black' }} ><strong>myLearn eLearning Platform</strong></h1>
                            <p class="certificate-title"><strong>are proud to present</strong></p>
                            <p class="student-name">{userInfo?.datas?.firstName} {userInfo?.datas?.lastName} </p>
                            <p class="certificate-title"><strong>this Certification of Achievement for the course</strong></p>
                            <div class="certificate-content">

                                <div class="about-certificate">
                                    <p>
                                        {myCourse?.mycourse?.courseInfo?.courseName}
                                    </p>
                                </div>
                                {/* <div class="about-certificate">
                                    <p>
                                        has completed [{myCourse?.mycourse?.courseInfo?.courseHours}] hours on {myCourse?.mycourse?.courseInfo?.courseName} on {moment(myCourse?.mycourse?.effectiveAt).format('DD-MM-YYYY')} [Date of Completion]
                                    </p>
                                </div> */}
                                {/* <p class="topic-title">
                                    The Topic consists of [hours] Continuity hours and includes the following:
                                </p>
                                <div class="text-center">
                                    <p class="topic-description text-muted">Contract adminitrator - Types of claim - Claim Strategy - Delay analysis - Thepreliminaries to a claim - The essential elements to a successful claim - Responses - Claim preparation and presentation </p>
                                </div> */}
                            </div>
                            <div class="certificate-footer text-muted" style={{ marginTop: 5 }}>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>______________________</p>
                                        <p>Dr. Ted Sun, PhD</p>
                                        <p>Vice Challenge of Academics</p>

                                    </div>
                                    <div class="col-md-4">
                                        <QRCode value={`${baseURL}/certificate/${mycourseId}/${myCourseOwnerBy}`} class="certqr" size={128} />
                                        <p>Date of issue: {moment(myCourse?.mycourse?.effectiveAt).format('DD-MM-YYYY')}</p>
                                    </div>
                                    <div class="col-md-4">

                                        <p>______________________</p>
                                        <p>surasak Sangdee</p>
                                        <p>Instructor</p>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }
            <button type="button" onClick={handleDownloadPdf}>
                Download as PDF
            </button>
        </>
    )
}

export default Certificate;