/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Card,
    CardImg,
    CardBody,
    CardText,
    ButtonToggle,
} from "reactstrap";
import InfoCard from './InfoCard';
import { useResetNewPassword } from "hooks/useResetNewPassword";

const ResetNewPassword = () => {
    const { resetCode } = useParams();
    const history = useHistory();
    const resetNewPassword = useResetNewPassword();

    useEffect(() => {

        console.log(' verify - code')

        //if (verifyCode != null && verifyCode != "")
        //  verifyAccount.onVerifyAccount(verifyCode);
    }, [resetCode])


    // useEffect(() => {
    //     if (verifyAccount?.datas == true)
    //         history.push('/login');
    // }, [verifyAccount?.datas])

    return (
        <Container fluid className="Course pt-5 text-dark">
            <Row>
                <Col xl="12">
                    {/* {decodeURIComponent(resetCode)} */}
                    <InfoCard
                        title={`Reset your password`}
                        message={"enter new password"}
                        resetCode={decodeURIComponent(resetCode)}
                        onResetNewPassword={resetNewPassword.onResetNewPassword}
                        isLoading={resetNewPassword.loading}
                        setIsLoading={resetNewPassword.setLoading}
                        errors={resetNewPassword.errors}
                        styles={{
                            position: 'absolute', left: '50%',
                            top: '50%'
                        }} />
                </Col>
            </Row>
        </Container>
    )
}

export default ResetNewPassword;