/* eslint-disable */
import './video-react.css';
import React, { Component } from 'react';
import { Player, ControlBar } from 'video-react';
import { Button } from 'reactstrap';
import { staggerHalf } from 'motionUtils';
import { GiTreasureMap } from 'react-icons/gi';

const sources = {
    sintelTrailer: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
    bunnyTrailer: 'http://media.w3.org/2010/05/bunny/trailer.mp4',
    bunnyMovie: 'http://media.w3.org/2010/05/bunny/movie.mp4',
    test: 'http://media.w3.org/2010/05/video/movie_300.webm'
};

const { REACT_APP_VIDEO_PATH } = process.env;

export default class VideoPlayer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            source: null,//sources.bunnyMovie,
            sourceList: null,
            beforeSeekTime: 0,
            playIndex: 0,
            currentMyLessonMediaId: null,
            allowUpdate: false,
            allowReplay: false,
            videoPosition: 0,
            seekToPosition: true,
            isAllow: false,
            isComplete: false,
            allowScheduleUpdate: true,
            isTestMode: false
        };

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.load = this.load.bind(this);
        this.changeCurrentTime = this.changeCurrentTime.bind(this);
        this.seek = this.seek.bind(this);
        this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
        this.changeVolume = this.changeVolume.bind(this);
        this.setMuted = this.setMuted.bind(this);
    }

    componentDidMount() {
        const { url, sourceList, allowReplay, isComplete, isAllow, isTestMode } = this.props;
        this.setState({ allowReplay: allowReplay });

        console.log('sourceList', sourceList);

        //const playList = !allowReplay ? sourceList.filter(({ isComplete }) => isComplete == false) : sourceList;

        const playList = sourceList;

        console.log('playList', playList)

        if (playList.length > 0) {
            this.setState({
                source: `${playList[0].lessonMediaUrl.includes("http") ? "" : REACT_APP_VIDEO_PATH}${playList[0].lessonMediaUrl}`,
                currentMyLessonMediaId: playList[0].myLessonMediaId,
                lessonId: playList[0].lessonId,
                lessonMediaId: playList[0].id,
                allowUpdate: true,
                videoPosition: playList[0].videoPosition,
                isAllow: isAllow,
                isComplete: isComplete,
                isTestMode: isTestMode
            });
        }

        console.log('media info ::: ', playList[0]);

        this.setState({ sourceList: sourceList });

        // subscribe state change
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    setMuted(muted) {
        return () => {
            this.player.muted = muted;
        };
    }

    handleStateChange(state) {

        const { onPlayState, onUpdateMyMedia, allowReplay, courseId, onClose, isTestMode } = this.props;

        this.setState({
            player: state
        });

        //console.log(state)


        //if (state.ended) {
        if (state) {

            // schdule update
            if (!state.ended) {

                const newSourceList = this.state.sourceList;
                const _source = this.state.source;
                const _lessonId = this.state.lessonId;
                let _playIndex = this.state.playIndex;
                const _lessonMediaId = this.state.lessonMediaId;
                const currentMyLessonMediaId = this.state.currentMyLessonMediaId;



                if (parseInt(state.currentTime) % 10 == 0) {


                    console.log('this.state.isTestMode >>>', isTestMode)
                    console.log('this.state.allowUpdate >>>>>> ', this.state.allowUpdate, ' >>> ', state.currentTime, ' >> this.allowScheduleUpdate ', this.state.allowScheduleUpdate)

                    if (onUpdateMyMedia && this.state.allowUpdate && state.currentTime > 0 && this.state.allowScheduleUpdate) {

                        if (allowReplay) {
                            this.setState({ allowScheduleUpdate: false })
                            console.log('update  >>>>>> ### 1')
                            onUpdateMyMedia(courseId, _lessonId, _lessonMediaId, currentMyLessonMediaId, { videoPosition: state.currentTime, isComplete: true }, () => {

                            });

                            setTimeout(
                                () => {
                                    this.setState({ allowScheduleUpdate: true })
                                },
                                1000
                            );

                        }
                        else
                            if (state.currentTime > this.state.videoPosition) {
                                this.setState({ allowScheduleUpdate: false })
                                console.log('update  >>>>>> ### 2')
                                onUpdateMyMedia(courseId, _lessonId, _lessonMediaId, currentMyLessonMediaId, { videoPosition: state.currentTime, isComplete: false }, () => {


                                });

                                setTimeout(
                                    () => {
                                        this.setState({ allowScheduleUpdate: true })

                                    },
                                    1000
                                );

                            }

                    }
                }
            }

            if (state.ended) {
                //if (state.currentTime > 15) {
                this.player.pause();
                const newSourceList = this.state.sourceList;
                const _source = this.state.source;
                const _lessonId = this.state.lessonId;
                let _playIndex = this.state.playIndex;
                const _lessonMediaId = this.state.lessonMediaId;
                const currentMyLessonMediaId = this.state.currentMyLessonMediaId;



                if (_playIndex < newSourceList.length) {
                    _playIndex++;
                    this.setState({ playIndex: _playIndex });
                }

                // update current playing video
                if (onUpdateMyMedia && this.state.allowUpdate && !allowReplay) {
                    this.setState({ allowUpdate: false });
                    onUpdateMyMedia(courseId, _lessonId, _lessonMediaId, currentMyLessonMediaId, { videoPosition: state.currentTime, isComplete: true }, onClose);
                }



                const _newSourceList = newSourceList.map(function (item) {
                    if (item.lessonMediaUrl === _source) {
                        return Object.assign({}, item, { isComplete: true });
                    }
                    return item;
                })

                this.setState({ sourceList: _newSourceList });
                const playList = allowReplay ? _newSourceList : _newSourceList.filter(({ isComplete }) => isComplete == false);
                if (playList.length > 0 && (_playIndex < _newSourceList.length)) {

                    this.setState({
                        source: `${REACT_APP_VIDEO_PATH}${playList[allowReplay ? _playIndex : 0].lessonMediaUrl}`,
                        currentMyLessonMediaId: playList[allowReplay ? _playIndex : 0].myLessonMediaId,
                        lessonId: playList[allowReplay ? _playIndex : 0].lessonId,
                        lessonMediaId: playList[allowReplay ? _playIndex : 0].id,
                        allowUpdate: true
                    });

                    this.player.load();

                }




            }
        }

        //console.log(state)
        if (onPlayState) {
            onPlayState(state)
        }
        // console.log(state)
        // copy player state to this component's state

        // auto seek to last played position
        if (this.state.isAllow && this.state.seekToPosition) {

            console.log("auto seek :: ", this.state.videoPosition)

            this.setState({ seekToPosition: false });
            setTimeout(
                () => {
                    this.player.seek(this.state.videoPosition);
                },
                100
            );
        }

        if (!allowReplay)
            if (!isTestMode)
                if ((state.seekingTime > state.currentTime)) {
                    if (state.currentTime > 0) {
                        this.setState({ beforeSeekTime: state.currentTime })
                        setTimeout(
                            () => {
                                this.player.seek(state.currentTime);
                            },
                            100
                        );

                    }
                }




    }

    play() {
        this.player.play();
    }

    pause() {
        this.player.pause();
    }

    load() {
        this.player.load();
    }

    changeCurrentTime(seconds) {
        return () => {
            const { player } = this.player.getState();
            this.player.seek(player.currentTime + seconds);
        };
    }

    seek(seconds) {


        //console.log(seconds)
        return () => {

            // const { player } = this.player.getState();
            // if (player.seekingTime > player.currentTime)
            //     this.player.seek(player.currentTime);

            this.player.seek(seconds);
        };
    }

    changePlaybackRateRate(steps) {
        return () => {
            const { player } = this.player.getState();
            this.player.playbackRate = player.playbackRate + steps;
        };
    }

    changeVolume(steps) {
        return () => {
            const { player } = this.player.getState();
            this.player.volume = player.volume + steps;
        };
    }

    changeSource(name) {
        return () => {
            this.setState({
                source: sources[name]
            });
            this.player.load();
        };
    }

    render() {
        return (
            <div>
                {this.props?.sourceList &&
                    <Player
                        ref={player => {
                            this.player = player;
                        }}
                        autoPlay
                    >
                        <source src={this.state.source} />
                        <ControlBar autoHide={false} />
                    </Player>}
                {/* <div className="py-3">
                    <Button onClick={this.play} className="mr-3">
                        play()
                    </Button>
                    <Button onClick={this.pause} className="mr-3">
                        pause()
                    </Button>
                    <Button onClick={this.load} className="mr-3">
                        load()
                    </Button>
                </div>
                <div className="pb-3">
                    <Button onClick={this.changeCurrentTime(10)} className="mr-3">
                        currentTime += 10
                    </Button>
                    <Button onClick={this.changeCurrentTime(-10)} className="mr-3">
                        currentTime -= 10
                    </Button>
                    <Button onClick={this.seek(50)} className="mr-3">
                        currentTime = 50
                    </Button>
                </div>
                <div className="pb-3">
                    <Button onClick={this.changePlaybackRateRate(1)} className="mr-3">
                        playbackRate++
                    </Button>
                    <Button onClick={this.changePlaybackRateRate(-1)} className="mr-3">
                        playbackRate--
                    </Button>
                    <Button onClick={this.changePlaybackRateRate(0.1)} className="mr-3">
                        playbackRate+=0.1
                    </Button>
                    <Button onClick={this.changePlaybackRateRate(-0.1)} className="mr-3">
                        playbackRate-=0.1
                    </Button>
                </div>
                <div className="pb-3">
                    <Button onClick={this.changeVolume(0.1)} className="mr-3">
                        volume+=0.1
                    </Button>
                    <Button onClick={this.changeVolume(-0.1)} className="mr-3">
                        volume-=0.1
                    </Button>
                    <Button onClick={this.setMuted(true)} className="mr-3">
                        muted=true
                    </Button>
                    <Button onClick={this.setMuted(false)} className="mr-3">
                        muted=false
                    </Button>
                </div>
                <div className="pb-3">
                    <Button onClick={this.changeSource('sintelTrailer')} className="mr-3">
                        Sintel teaser
                    </Button>
                    <Button onClick={this.changeSource('bunnyTrailer')} className="mr-3">
                        Bunny trailer
                    </Button>
                    <Button onClick={this.changeSource('bunnyMovie')} className="mr-3">
                        Bunny movie
                    </Button>
                    <Button onClick={this.changeSource('test')} className="mr-3">
                        Test movie
                    </Button>
                </div>
                <div>State</div> */}

            </div>
        );
    }
}