/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const DropdownLanguage = () => {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState("th");

  const handleLangChange = evt => {
    const lang = evt.target.value;
    //console.log(lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);

    // i18n.changeLanguage(lang, function (err, t) {
    //   // setTimeout(() => {
    //   //   window.location.reload(true);
    //   // }, 1000);
    // });

    // setTimeout(() => {
    //   window.location.reload(true);
    // }, 1000);

  };

  return (
    <select onChange={handleLangChange} value={language}>
      <option value="th">{t('lang_thai_name')}</option>
      <option value="en">{t('lang_eng_name')}</option>
    </select>
  );
};

export default DropdownLanguage;
