/* eslint-disable */
import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useIntersection } from './intersectionObserver';
import './imageRenderer.scss';

import defaultBanner from 'assets/images/dummy247x139.png';

export const generateNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const ImageRenderer = ({ url, thumb, width, height, onError }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(true);
  const [defaultUrl, setDefaultUrl] = useState();
  const [imgUrl, setImgUrl] = useState(defaultBanner);

  const ran = generateNumber(1, 1000);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };






  return (
    <>
      {/* {!isLoaded &&
        <div
          className="image-container"
          ref={imgRef}
        >

          <img
            className={classnames('image')}
            src={imgUrl}
          />
        </div>} */}


      <div
        className="image-container"
        ref={imgRef}
      >
        {isInView && (
          <>
            <img
              className={classnames('image', {
                ['isLoaded']: !!isLoaded
              })}
              src={url}
              onLoad={handleOnLoad}
              onError={onError}
              style={{ width: '100%' }}
            />
          </>
        )}
      </div>


    </>
  )



  // if (isLoaded) {
  //   return (
  //     <div
  //       className="image-container"
  //       ref={imgRef}
  //     >
  //       {isInView && (
  //         <>
  //           <img
  //             className={classnames('image', {
  //               ['isLoaded']: !!isLoaded
  //             })}
  //             src={url}
  //             onLoad={handleOnLoad}
  //             onError={onError}
  //           />
  //         </>
  //       )}
  //     </div>
  //   )
  // }


};

export default ImageRenderer;
