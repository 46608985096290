/* eslint-disable */
import React, { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useCourse };

const courseByCategoryAtom = atom({
    key: 'courseByCategoryAtom',
    default: []
});

const courseLoading = atom({
    key: 'courseLoading',
    default: false
});

const courseBannerAtom = atom({
    key: 'courseBannerAtom',
    default: []
});


function useCourse() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [categories, setCategories] = useRecoilState(courseByCategoryAtom);
    const [banners, setBanners] = useRecoilState(courseBannerAtom);
    const [loading, setLoading] = useRecoilState(courseLoading);

    useMemo(() => {
        //  getCategories();
    }, []);



    return {
        getCategories, getCategoriesPassed, getCategoriesLearning, getBanner, setLoading,
        categories: categories, banners, loading
    }




    async function getBanner() {
        setLoading(true);
        let req =
        {
            criteria: {
                num: 1
            },
            pageIndex: 0,
            pageSize: 24
        }

        return fetchWrapper.get(`${baseUrl}/courses/banner/1`, req)
            .then(response => {
                //console.log(" **** banner response **** ")
                //console.log(response?.data)
                setBanners(response?.data);
                setLoading(false);
            });
    }


    async function getCategories(category) {

        setLoading(true);

        let req =
        {
            criteria: {
                CategoryCode: category
            },
            pageIndex: 0,
            pageSize: 24
        }

        return fetchWrapper.post(`${baseUrl}/products/categories`, req)
            .then(response => {
                //console.log(response?.data)
                setCategories(response?.data);
                setLoading(false);
            });
    }


    async function getCategoriesPassed(category) {
        setLoading(true);
        let req =
        {
            criteria: {
                CategoryCode: category
            },
            pageIndex: 0,
            pageSize: 24
        }

        return fetchWrapper.post(`${baseUrl}/products/categories/passed`, req)
            .then(response => {
                //console.log(response?.data)
                setCategories(response?.data);
                setLoading(false);
            });
    }



    async function getCategoriesLearning(category) {
        setLoading(true);
        let req =
        {
            criteria: {
                CategoryCode: category
            },
            pageIndex: 0,
            pageSize: 24
        }

        return fetchWrapper.post(`${baseUrl}/products/categories/learning`, req)
            .then(response => {
                // console.log(response?.data)
                setCategories(response?.data);
                setLoading(false);
            });
    }




}