/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    //   CardTitle,
    //   CardText,
    Row,
    Col,
    Table,
    CardImg,
} from "reactstrap";

import i18n from "i18next";
import { FaPlay, FaCheckDouble, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import { usePlayVideo } from "hooks/usePlayVideo";
import { useExamModal } from "hooks/useExamModal";
import { useAuthen } from "hooks/useAuthen";
import { useMyExams } from "hooks/useMyExams";

const ExamSummary = ({ course, isRegister }) => {

    const playerActions = usePlayVideo();
    const examModal = useExamModal();
    const userActions = useAuthen();
    const myExams = useMyExams();

    useEffect(() => {
        myExams.onGetExamSummary(course?.id);
    }, [course])


    useEffect(() => {
        // console.log(myExams?.examSummary)
    }, [myExams?.examSummary]);


    return (
        <Table striped >
            <tbody>
                {(userActions?.auth && isRegister) && myExams?.examSummary?.data?.map((exam) => (
                    <tr>
                        <td width={200}>
                            {i18n.language == "en"
                                ? exam.myExamName
                                : exam.myExamNameLocale}
                        </td>
                        <td >
                            ทั้งหมด {exam.allTest} ข้อ
                        </td>
                        <td >
                            {exam.correctTest} คะแนน
                        </td>
                        {/* <td>
                            {i18n.language == "en"
                                ? lesson.lessonTitle
                                : lesson.lessonTitleLocale}
                        </td> */}
                        {/* <td>@mdo</td> */}
                    </tr>
                ))}

            </tbody>
        </Table>
    )
}

export default ExamSummary;