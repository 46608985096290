/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Card,
    CardImg,
    CardBody,
    CardText,
    ButtonToggle,
} from "reactstrap";
import { useVerifyAccount } from "hooks/useVerifyAccount";
import InfoCard from './InfoCard';
import { useCheckMail } from 'hooks/useCheckMail';
import ChangePassword from 'components/ChangePassword';
import { useResetPassword } from 'hooks/useResetPassword';
import ResetPassword from "./index-";

const ResetPasswordPage = () => {

    const history = useHistory();

    const resetPassword = useResetPassword();

    // useEffect(() => {

    //     console.log(' verify - code')

    //     if (verifyCode != null && verifyCode != "")
    //         verifyAccount.onVerifyAccount(verifyCode);
    // }, [verifyCode])


    useEffect(() => {
        //  if (verifyAccount?.datas == true)
        //     history.push('/login');
        console.log('check mail data:')
        //console.log(pCheckmail?.data)
    }, [resetPassword?.datas])

    return (
        <Container fluid className="Course pt-5 text-dark" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Row>
                <Col xl="12" >
                    <InfoCard
                        title={`Reset your password`}
                        message={"enter your email"}
                        onResetPassword={resetPassword.onResetPassword}
                        isLoading={resetPassword.loading}
                        setIsLoading={resetPassword.setLoading}
                        errors={resetPassword.errors}
                        styles={{
                            position: 'absolute', left: '50%',
                            top: '50%'
                        }} />
                </Col>
            </Row>
            {/* <ChangePassword /> */}
        </Container>
    )
}

export default ResetPasswordPage;