/* eslint-disable */
import './detailModal.scss'
import { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"
import { staggerOne, modalOverlayVariants, modalVariants, modalFadeInUpVariants } from "../../motionUtils";
import { hideModalDetail } from "../../redux/modal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectModalContent, selectModalState } from "../../redux/modal/modal.selectors";
import { BASE_IMG_URL, FALLBACK_IMG_URL, BASE_API_URL } from "../../requests";
import { VscChromeClose } from "react-icons/vsc";
import { dateToYearOnly } from "_helpers/utils";
import { FaMinus, FaPlay, FaPlus, FaHeart } from "react-icons/fa";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import useOutsideClick from "../../hooks/useOutsideClick";
import ImageRenderer from 'components/ImageRenderer/ImageRenderer';
import { useApi } from 'hooks/useApi';
import apiEndpoint from 'services/api-config';
import { apiBaseURL } from '_helpers';


import {
	useParams,
	useLocation,
	useHistory,
	useRouteMatch
} from "react-router-dom";

export const generateNumber = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min)
}


const DetailModal = () => {

	const router = useHistory();
	const api = useApi();
	const [overviewContent, setContent] = useState();

	const dispatch = useDispatch();
	const modalClosed = useSelector(selectModalState);
	const modalContent = useSelector(selectModalContent);
	const handleModalClose = () => dispatch(hideModalDetail());
	const { item, courseId, overview, code, fallbackTitle, titleImageUrl, backdrop_path, release_date, first_air_date, genresConverted, isFavourite, poster, posterImageUrl, posterImageId } = modalContent;
	const joinedGenres = genresConverted ? genresConverted.join(', ') : "Not available";
	//const maturityRating = adult === undefined ? "Not available" : adult ? "Suitable for adults only" : "Suitable for all ages";
	const reducedDate = release_date ? dateToYearOnly(release_date) : first_air_date ? dateToYearOnly(first_air_date) : "Not Available";
	const modalRef = useRef();
	const [bannerImage, setBannerImage] = useState()

	const handleAdd = (event) => {
		event.stopPropagation();
		dispatch(addToFavourites({ ...modalContent, isFavourite }));
	}
	const handleRemove = (event) => {
		event.stopPropagation();
		dispatch(removeFromFavourites({ ...modalContent, isFavourite }));
		if (!modalClosed) handleModalClose();
	}
	const handlePlayAnimation = event => {
		event.stopPropagation();
		handleModalClose();

		router.push(`/course/${courseId}`);
	};
	useOutsideClick(modalRef, () => {
		if (!modalClosed) handleModalClose();
	});

	useEffect(() => {
		console.log(modalClosed)
	}, [modalClosed])

	useEffect(async () => {
		console.log(courseId)

		let _masterKey = courseId;
		await api.onSetData(_masterKey, []);

		if (courseId)
			await api.onGet({
				masterKey: _masterKey,
				aKey: courseId,
				baseApi: `courses/${courseId}/contents`,
				fieldName: '',
				data: {},
				onSuccess: (resp) => {
					setContent(resp.data[0]?.contentDescriptionLocale);
				}
			});

		//let mydata = await api.onGetData(courseId);
		//console.log(mydata);

	}, [courseId])

	useEffect(() => {
		const baseUrl = apiEndpoint;// "https://mylearn-api.devz.sbs/api";
		const ran = generateNumber(1, 10000);
		const imgUrl = `${apiBaseURL}/images/thumbor-resize/` + posterImageId + "/1206/500";//posterImageUrl;//baseUrl + "/images/banners/1206/654?ran=" + ran;
		setBannerImage(imgUrl);
		// const img = new Image(259, isLarge ? 259 : 146)
		// img.src = imgUrl;
		// img.onload = () => {
		// 	setBannerImage(imgUrl);
		// 	setLoadingImage(false)
		// }

		//console.log(courseId + " >> loading..." + imgUrl);

	}, [posterImageId])


	const onError = (ev) => {
		//setDefaultUrl(defaultBanner);
		//const baseUrl = "https://mylearn-api.devz.sbs/api";
		const ran = generateNumber(1, 10000);
		const imgUrl = apiBaseURL + "/images/banners/1206/654?ran=" + courseId;
		setBannerImage(imgUrl);
		//console.log("load imag  error !!!!...");
		ev.target.src = imgUrl;

	};

	return (
		<AnimatePresence exitBeforeEnter>
			{!modalClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="modalOverlay"
						className={`Modal__overlay ${modalClosed && 'Modal__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={modalVariants}
							ref={modalRef}
							className={`Modal__wrp ${modalClosed && 'Modal__invisible'}`}
						>
							<motion.button
								className="Modal__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<div className="Modal__image--wrp">
								<div className="Modal__image--shadow" />

								<ImageRenderer
									className="Modal__image--img"
									key={fallbackTitle}
									url={bannerImage}
									thumb={'https://i.imgur.com/67r0FdBm.jpg'}
									width={1206}
									height={654}
									onError={onError}
								/>

								{/* <img
									className="Modal__image--img"

									src={poster ? poster?.posterUrl : (poster?.posterUrl ? poster?.posterUrl : FALLBACK_IMG_URL)}
									alt={fallbackTitle}
								/> */}


								<div className="Modal__image--buttonswrp">
									<button
										className="Modal__image--button"
										onClick={handlePlayAnimation}
									// to={'/play'}
									//to={`/course/${courseId}`}
									>
										<FaPlay />
										<span>ลงทะเบียน / เรียน</span>
									</button>
									{/* {!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaHeart />
											</button>
										) : (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaHeart />
											</button>
										)} */}
								</div>
							</div>
							<motion.div variants={staggerOne} initial="initial" animate="animate" exit="exit" className="Modal__info--wrp">
								<motion.h3 variants={modalFadeInUpVariants} className="Modal__info--title">{fallbackTitle}</motion.h3>
								<motion.p variants={modalFadeInUpVariants} className="Modal__info--description">{overviewContent}</motion.p>
								<motion.hr variants={modalFadeInUpVariants} className="Modal__info--line" />
								<motion.h4 variants={modalFadeInUpVariants} className="Modal__info--otherTitle"><b>{`หลักสูตรบังคับ`}</b></motion.h4>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Categories: </span>
									<span className="Modal__info--row-description">{joinedGenres}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>
										{release_date ? "Release date: " : "First air date: "}
									</span>
									<span className="Modal__info--row-description">{reducedDate}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Reviseion: </span>
									<span className="Modal__info--row-description">{`1.0`}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Chapter: </span>
									<span className="Modal__info--row-description">{`10`}</span>
								</motion.div>
								<motion.div variants={modalFadeInUpVariants} className="Modal__info--row">
									<span className='Modal__info--row-label'>Learners: </span>
									<span className="Modal__info--row-description">{`9999`}</span>
								</motion.div>
							</motion.div>
						</motion.div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	)
}

export default DetailModal
