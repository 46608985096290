/* eslint-disable */
// import 'bootstrap/dist/css/bootstrap.css';
import "./course.scss";
import React, { useEffect, useMemo, useState } from "react";
import moment from 'moment';
import { numberFormatter } from '_helpers/utils';
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
  CardBody,
  CardText,
  ButtonToggle, Spinner,
} from "reactstrap";
import CourseDetail from "../../components/CourseDetail/CourseDetail";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useRecoilValue } from "recoil";
import { courseAtom } from "_state";
import { useCourseActions } from "_actions";
import { useMyCourse } from "hooks/useMyCourse";
import { useAuthen } from "hooks/useAuthen";
import ImageRenderer from 'components/ImageRenderer/ImageRenderer';
import Swal from 'sweetalert2';
import { apiBaseURL } from '_helpers';

// import { motion } from "framer-motion";
// import { defaultPageFadeInVariants } from "../../motionUtils";


export const generateNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}






const Course = () => {
  const { t } = useTranslation();

  const summaryData = [
    {
      id: uuid(),
      code: 1,
      name: t('course_teacher_label'),
      value: 'อ.เดวิด เฮลด์.'
    },
    {
      id: uuid(),
      code: 2,
      name: t('course_lesson_label'),
      value: '10 บทเรียน'
    },
    {
      id: uuid(),
      code: 3,
      name: t('course_hours_label'),
      value: '10 ชั่วโมง'
    },
    {
      id: uuid(),
      code: 4,
      name: t('course_registers_label'),
      value: '999 ท่าน'
    },
    {
      id: uuid(),
      code: 5,
      name: t('course_lastupdate_label'),
      value: '10/10/2021'
    }
  ];

  const [bannerImage, setBannerImage] = useState()
  const [courseSummary, setCourseSummary] = useState(summaryData)
  const { courseId } = useParams();

  const [teacherName, setTeacherName] = useState('');
  const [lessonNum, setLessonNum] = useState('');
  const [courseHours, setCourseHours] = useState('');
  const [registered, setRegistered] = useState('');
  const [lastUpdate, setLastUpdate] = useState();


  const courseActions = useCourseActions();
  //const course = courseActions.Courses;
  const course = useRecoilValue(courseAtom);
  const auth = useAuthen();
  const mycourse = useMyCourse();
  //const courseActions = useCourseActions();

  const onError = (ev) => {
    //setDefaultUrl(defaultBanner);
    //const baseUrl = "https://mylearn-api.devz.sbs/api";
    const ran = generateNumber(1, 10000);
    const imgUrl = apiBaseURL + "/images/banners/1920/1080?ran=" + ran;
    setBannerImage(imgUrl);
    //console.log("load imag  error !!!!...");
    ev.target.src = imgUrl;

  };





  // useMemo( () => {
  //   const courseActions = useCourseActions();
  //   const courseData = await courseActions.getAll();
  //   console.log(courseData)
  // }, [])

  useMemo(() => {
    const baseUrl = "https://mylearn-api.devz.sbs/api";
    const ran = generateNumber(1, 10000);
    //const imgUrl = baseUrl + "/images/banners/1920/1080?ran=" + ran;
    const imgUrl = `${apiBaseURL}/images/thumbor-resize/` + course?.posterImageId + "/1206/654";
    setBannerImage(imgUrl);
  }, [course])


  useEffect(() => {
    courseActions.getCourse(courseId); //"fe1be5f3-5fa6-4c8b-8416-b72331b0ee81"
    //console.log(mycourse?.courses);
    //course = courseActions.Courses;
  }, [])


  useEffect(() => {
    console.log("mycourse");
    console.log(mycourse?.courses);
    mycourse.isRegister(courseId)
    //course = courseActions.Courses;
  }, [mycourse?.courses])


  useEffect(() => {
    const _summaryData = courseSummary.map((itm) => {
      if (itm.code == 1) {
        // itm.value = (course?.teacher?.teacherName == null ? "" : course?.teacher?.teacherName);
        setTeacherName(course?.teacher?.teacherName == null ? "" : course?.teacher?.teacherName);
      }

      if (itm.code == 2)
        setLessonNum(course?.lessonNum == null ? "" : course?.lessonNum);
      //itm.value = (course?.lessonNum == null ? "" : course?.lessonNum) + ' ' + t('course_lesson_label');

      if (itm.code == 3) {
        let hrs = 2.30;

        //({ isPrivate }) => isPrivate == false
        const lessonMedias = course?.lessons?.filter(({ lessonMedias }) => lessonMedias.length > 0).map(lesson => {
          return lesson.lessonMedias[0];
        });

        //videoLength


        console.log('filter lesson media', lessonMedias)

        // console.log((lessonMedias.reduce((a, v) => a = a + v.videoLength, 0)))

        const result = lessonMedias?.reduce((total, currentValue) => total = total + currentValue.videoLength, 0);

        setCourseHours(course?.courseHours == null ? "1" : numberFormatter(result / 60, 2))
      }
      //itm.value = (course?.courseHours == null ? "" : course?.courseHours) + '  ชั่วโมง';

      if (itm.code == 4)
        setRegistered(course?.registered == null ? "" : course?.registered)
      //itm.value = (course?.registered == null ? "" : course?.registered) + ' ท่าน';

      return itm;
    })
    setCourseSummary(_summaryData);

    setLastUpdate(course?.updatedAt);  //
  }, [course])


  return (
    <Container fluid className="Course">
      {!course &&
        <Row className="mt-500">
          <Col sm="1" className='container center'>
            <Spinner animation="border" variant="primary" className="mt-5" />
          </Col>
        </Row>

      }
      {course &&
        <Row>
          <Col xl="9">
            {course &&
              <CourseDetail course={course} isRegister={mycourse?.isregister} onRefreshCourse={courseActions.getCourse} onCompleteCourse={courseActions.completeCourse} />}
          </Col>
          <Col xl="3">
            <div>
              <Card className="border-0">

                <CardImg
                  top
                  width="100%"
                  src={bannerImage}
                  alt="Card image cap"
                />

                {/* <ImageRenderer
                width="100%"
                key={1}
                url={bannerImage}
                thumb={'https://i.imgur.com/67r0FdBm.jpg'}
                width={1920}
                height={1080}
                onError={onError}
              /> */}
                <CardBody className="card-info">
                  <CardText>
                    <ListGroup flush>
                      <ListGroupItem className="bg-transparent" >
                        <span> {t('course_teacher_label')}</span>
                        <span className="float-right">{teacherName}</span>
                      </ListGroupItem>
                      <ListGroupItem className="bg-transparent" >
                        <span> {t('course_lesson_label')}</span>
                        <span className="float-right">{lessonNum}  {t('course_lesson_label')}</span>
                      </ListGroupItem>
                      <ListGroupItem className="bg-transparent" >
                        <span>{t('course_hours_label')}</span>
                        <span className="float-right">{courseHours} {t('course_lesson_hours')}</span>
                      </ListGroupItem>
                      <ListGroupItem className="bg-transparent" >
                        <span>{t('course_registers_label')}</span>
                        <span className="float-right">{registered} {t('course_lesson_persons')}</span>
                      </ListGroupItem>
                      <ListGroupItem className="bg-transparent" >
                        <span>{t('course_lastupdate_label')}</span>
                        <span className="float-right">{moment(lastUpdate).format('DD-MM-YYYY')}</span>
                      </ListGroupItem>
                      {/* {courseSummary.map(itm => (
                        <ListGroupItem className="bg-transparent" >
                          <span>{itm.name}</span>
                          <span className="float-right">{itm.value}</span>
                        </ListGroupItem>
                      ))} */}
                    </ListGroup>
                  </CardText>

                </CardBody>
              </Card>
            </div>
            <div className="text-center m-5">
              {(auth.auth && course) &&
                <ButtonToggle color={!mycourse?.isregister ? "primary" : "secondary"} className="btn-lg"
                  disabled={mycourse?.isregister}
                  onClick={() => {
                    Swal.fire({
                      title: 'ยืนยันลงทะเบียนเรียน ?',
                      text: 'ลงทะเบียนเรียน',
                      icon: 'infomation',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'ตกลง',
                      cancelButtonText: 'ยกเลิก',
                    }).then((result) => {
                      if (result.isConfirmed) {

                        mycourse?.registerCourse(course?.id);

                        Swal.fire(
                          'ลงทะเบียนเรียน!',
                          'ลงทะเบียนเรียบร้อย.',
                          'success'
                        )

                      }
                    })
                  }


                  }
                >
                  {t(mycourse?.isregister ? 'couse_already_register_label' : 'couse_register_label')}
                </ButtonToggle>}
            </div>
          </Col>
        </Row>}
    </Container >
  );
};

export default Course;
