/* eslint-disable */
import React, { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useResetPassword };

const dataAtom = atom({
    key: 'ResetPassword',
    default: false
});


function useResetPassword() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [datas, setDatas] = useRecoilState(dataAtom);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);

    return {
        onResetPassword,
        datas, loading, errors, setLoading
    }
    function onResetPassword(_email) {
        //console.log(apiBaseURL)

        return resetPassword(_email)
    }

    async function resetPassword(_email) {

        setLoading(true)

        let req =
        {
            email: _email
        }

        return fetchWrapper.post(`${baseUrl}/accounts/forgot-password`, req)
            .then(response => {
                setDatas(response);
                setLoading(false);

                setTimeout(() => {
                    history.push('/please-check-email')
                }, 500);

            });
    }



}