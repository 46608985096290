/* eslint-disable */
import React, { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useResetNewPassword };

const dataAtom = atom({
    key: 'ResetPassword',
    default: false
});


function useResetNewPassword() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [datas, setDatas] = useRecoilState(dataAtom);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(null);

    return {
        onResetNewPassword,
        datas, loading, errors, setLoading
    }
    function onResetNewPassword(req) {
        //console.log(apiBaseURL)

        return resetNewPassword(req)
    }

    async function resetNewPassword(req) {
        setLoading(true);

        let _req =
        {
            email: req.email,
            password: req.password,
            confirmPassword: req.confirmPassword,
            resetCode: req.resetCode

        }


        return fetchWrapper.post(`${baseUrl}/accounts/reset-password`, _req)
            .then(response => {
                //console.log('verify - response ')
                //console.log(response)
                setDatas(response);
                setLoading(false);
                setTimeout(() => {
                    history.push('/please-check-email')
                }, 500);
            });
    }



}