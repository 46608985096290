/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useMyLessons };

const myLessonsAtom = atom({
    key: 'myLessons',
    default: []
});

const isRegisterCourseAtom = atom({
    key: 'isRegisterCourse',
    default: false
});


function useMyLessons() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [mycourses, setMyCourses] = useRecoilState(myLessonsAtom);
    const [isregister, setIsRegister] = useRecoilState(isRegisterCourseAtom);


    useMemo(() => {
        //  getMyCourse();
    }, []);



    return {
        onUpdate
    }

    async function onUpdate(courseId, lessonId, req) {
        // let req =
        // {
        //     criteria: {
        //         searchText: ""
        //     },
        //     pageIndex: 0,
        //     pageSize: 24
        // }

        ///api/my-lessons/{courseId}/{lessonId}
        return fetchWrapper.put(`${baseUrl}/my-lessons/${courseId}/${lessonId}`, req)
            .then(response => {
                console.log(response)
                //setMyCourses(response?.data);
            });
    }





}