/* eslint-disable */
import './index.scss'
import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"
import { staggerOne, modalOverlayVariants, modalVariants, modalFadeInUpVariants } from "../../motionUtils";
import { hideModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayModalContent, selectPlayModalState } from "../../redux/playmodal/modal.selectors";
import { BASE_IMG_URL, FALLBACK_IMG_URL, BASE_API_URL } from "../../requests";
import { VscChromeClose } from "react-icons/vsc";
import { dateToYearOnly } from "_helpers/utils";
import { FaMinus, FaPlay, FaPlus, FaHeart } from "react-icons/fa";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import useOutsideClick from "../../hooks/useOutsideClick";

import Certificate from 'components/Certificate';
import { useShowCertificate } from 'hooks/useShowCertificate';
// ref = player => {
// 	this.player = player
// }



const PlayModal = () => {



	const showCertificate = useShowCertificate();
	const modalClosed = showCertificate.isShow;//useSelector(selectPlayModalState);
	const modalData = showCertificate.showData;
	const modalContent = useSelector(selectPlayModalContent);


	const { item, courseId, overview, code, fallbackTitle, titleImageUrl, backdrop_path, release_date, first_air_date, genresConverted, isFavourite, poster } = modalContent;
	//const { myCourseId } = modalData?.datas;
	const joinedGenres = genresConverted ? genresConverted.join(', ') : "Not available";
	//const maturityRating = adult === undefined ? "Not available" : adult ? "Suitable for adults only" : "Suitable for all ages";
	const reducedDate = release_date ? dateToYearOnly(release_date) : first_air_date ? dateToYearOnly(first_air_date) : "Not Available";
	const modalRef = useRef();

	const handleModalClose = () => {
		// console.log("stop at >> ", playPosition);
		// console.log("courseId >> ", modalData?.datas?.courseId);
		// console.log("lessonId >> ", modalData?.datas?.lessonId);

		// if (modalData?.onRefreshCourse) {
		// 	console.log(' refreshing......')
		// 	modalData?.onRefreshCourse(modalData?.datas?.courseId)
		// }

		// if (modalData?.onCompleteCourse) {
		// 	console.log(' onCompleteCourse......')
		// 	modalData?.onCompleteCourse(modalData?.datas?.courseId)
		// }

		showCertificate.setDisableShow()
	};


	useOutsideClick(modalRef, () => {
		//if (!modalClosed) handleModalClose();
	});






	useEffect(() => {
		console.log('modalData >>>');
		console.log(modalData);
	}, [modalData]);

	return (
		<AnimatePresence exitBeforeEnter>
			{!modalClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="modalOverlay"
						className={`Modal__overlay ${modalClosed && 'Modal__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={modalVariants}
							style={{ height: 'auto' }}
							className={`Modal__wrp ${modalClosed && 'Modal__invisible'}`}
						>
							<motion.button
								className="Modal__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<Certificate mycourseId={modalData?.datas?.myCourseId} myCourseOwnerBy={modalData?.datas?.myCourseOwnerBy} />
							{/* <ReactPlayer url={`${modalData?.url}`} className="Modal__image--img"

								//ref={player}
								ref={player}
								controls={true}
								width='100%'
								height='100%'
								playing={playing}
								onProgress={handleProgress}
								onDuration={handleDuration}
								onPause={handlePause}
								onSeek={handleSeek}
							/> */}
							<div className="Modal__image--wrp">
								<div className="Modal__image--shadow" />

								{/* <img
									className="Modal__image--img"

									src={poster ? poster?.posterUrl : (poster?.posterUrl ? poster?.posterUrl : FALLBACK_IMG_URL)}
									alt={fallbackTitle}
								/> */}
								{/* <div className="Modal__image--buttonswrp">
									<Link
										className="Modal__image--button"
										onClick={handlePlayAnimation}
										// to={'/play'}
										to={`/course/${courseId}`}
									>
										<FaPlay />
										<span>ลงทะเบียน / เรียน</span>
									</Link>
									{!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaHeart />
											</button>
										) : (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaHeart />
											</button>
										)}
								</div> */}
							</div>

						</motion.div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	)
}

export default PlayModal
