/* eslint-disable */
import Course from 'pages/Course/Course';
import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { useShowCertificate } from 'hooks/useShowCertificate';
import { Link } from "react-router-dom";
import moment from 'moment';
import { useAuthen } from "hooks/useAuthen";
import i18n from "i18next";

const CourseInfoCard = ({ course }) => {
    const showCertificate = useShowCertificate();
    const auth = useAuthen();
    return (
        <div>
            <Card style={{ border: 0, color: '#154C9C' }}>
                {/* <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180" alt="Card image cap" /> */}
                <CardBody>
                    {/* <CardTitle>{course && `${course?.courseCode} - ${course && course?.courseName}`}</CardTitle> */}
                    <CardTitle>{course && `${course && i18n.language == 'en' ? course?.courseName : course?.courseNameLocale}`}</CardTitle>
                    {auth.auth && course && course?.isLearningComplete &&
                        <CardSubtitle>{`เริ่มต้น ${moment(course?.myCourseStartAt).format('DD-MM-YYYY HH:MM')} ถึง ${moment(course?.myCourseCompleteAt).format('DD-MM-YYYY HH:MM')} `}
                            <Link
                                to={"#"}
                                onClick={() => {

                                    let datas = {
                                        datas: {
                                            myCourseId: course?.myCourseId,
                                            myCourseOwnerBy: course?.myCourseOwnerBy,
                                        },
                                    };
                                    window.open(`https://www.elearning.bus.rmutt.ac.th/apis/s8/api/reports/certificate-1/${course?.myCourseAid}`);

                                    //  showCertificate.setShow(datas)
                                }}
                            >
                                {`Certificate`}
                            </Link>
                        </CardSubtitle>}
                    {/* <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
                    <Button>Button</Button> */}
                </CardBody>
            </Card>
        </div>
    );
};

export default CourseInfoCard;