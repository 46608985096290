/* eslint-disable */
import { useRecoilState } from 'recoil';

import { history } from './history';
import { authAtom } from 'hooks/useAuthen';
import instance from './api';

const { REACT_APP_API_URL, REACT_APP_API_URL_STAGING, REACT_APP_API_URL_PROD, REACT_APP_ENV, REACT_APP_API_URL_DEV,
    REACT_APP_BASE_URL, REACT_APP_BASE_URL_DEV, REACT_APP_BASE_URL_STAGING, REACT_APP_BASE_URL_PROD
} = process.env;

const apiBaseURL = REACT_APP_ENV == "production" ? REACT_APP_API_URL_PROD : (REACT_APP_ENV == "staging" ? REACT_APP_API_URL_STAGING : (REACT_APP_ENV == "development" ? REACT_APP_API_URL_DEV : REACT_APP_API_URL));
const baseURL = REACT_APP_ENV == "production" ? REACT_APP_BASE_URL_PROD : (REACT_APP_ENV == "staging" ? REACT_APP_BASE_URL_STAGING : (REACT_APP_ENV == "development" ? REACT_APP_BASE_URL_DEV : REACT_APP_BASE_URL));


export { useFetchWrapper, apiBaseURL, baseURL };


function useFetchWrapper() {
    const [auth, setAuth] = useRecoilState(authAtom);

    // return {
    //     get: request('GET'),
    //     post: request('POST'),
    //     put: request('PUT'),
    //     delete: request('DELETE')
    // };

    return instance;

    function request(method) {
        return (url, body) => {
            const requestOptions = {
                method,
                headers: authHeader(url),
                url: url
            };


            const apiRequestOptions = {
                method: method,
                url: url,
                data: body
                // headers: authHeader(url),

            };


            // axios({
            //   method: 'post',
            //   url: `${baseUrl}/accounts/login`,
            // }).then((response) => {
            //   console.log(response.data);
            // });

            if (body) {
                //apiRequestOptions.headers['Content-Type'] = 'application/json';
                //apiRequestOptions.data = JSON.stringify(body);
                //   requestOptions.url = url;
            }

            console.log(' api request :::::: ');
            console.log(apiRequestOptions)

            //return fetch(url, requestOptions).then(handleResponse);
            return instance(apiRequestOptions);
        }
    }

    // helper functions
    function authHeader(url) {
        // return auth header with jwt if user is logged in and request is to the api url
        const token = auth?.accessToken;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(apiBaseURL);
        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }

    function handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);

            if (!response.ok) {
                if ([401, 403].includes(response.status) && auth?.token) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('user');
                    setAuth(null);
                    history.push('/login');
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
            //return {}
        });
    }
}