/* eslint-disable */
import "./homepage.scss";

import { useRecoilValue } from 'recoil';
import { authAtom, usersAtom } from '../../_state';
import { useUserActions } from '../../_actions';

import { v4 as uuid } from 'uuid';
import { useState, useEffect, useMemo } from "react";
import Banner from "../../components/Banner/Banner";
//import Row from "../../components/Row/Row";
import Row2 from "../../components/Row/Row2";
import Credits from "../../components/Credits/Credits";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";
import { useRetrieveCourse } from '../../hooks/useRetrieveCourse';
import { useCourse } from "hooks/useCourse";
import i18n from 'i18next';
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Spinner, Row, Col, Container
} from "reactstrap";

const Homepage = () => {
  const courses = useCourse();
  // for banner

  const location = useLocation()

  useEffect(() => {
    console.log('home...', location.pathname)

    courses.getCategories("");
    courses.getBanner();
  }, [location.pathname, i18n.language])


  // useEffect(() => {
  //   window.location.reload();
  // }, [i18n.language])


  return (
    <Container fluid >
      {courses.loading &&
        <Row className="mt-500">
          <Col sm="1" className='container center'>
            <Spinner animation="border" variant="primary" className="mt-5" />
          </Col>
        </Row>

      }

      {(!courses?.loading && courses?.categories) &&
        <motion.div
          className="Homepage"
          variants={defaultPageFadeInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <Banner banners={courses?.banners} />
          {courses?.categories?.map((cat, idx) => (
            <Row2 key={cat.productCategoryId}
              datas={cat.items} title={i18n.language == "en" ? cat.productCategoryName : cat.productCategoryNameLocale} genre={""} isLarge={cat.isLarge}
            />
          ))}



          {/* <Row2 key="no1111"
      datas={course.data}  title={"กำลังเรียน"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"มาใหม่"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"บังคับหรือแนะนำ"} genre={""} isLarge = {false}
      />
      <Row2 key="no1111"
      datas={course.data}  title={"กลุ่มที่สนใจ"} genre={""} isLarge = {false}
      /> */}

          {/* <Row key='asihd9a8sd' {...testRow} /> */}

          {/* {rows && rows.map((props) => <Row key={props.id} {...props} />)} */}
          <Credits />
        </motion.div>}
    </Container>
  );
};

export default Homepage;
