/* eslint-disable */
import React, { Fragment } from 'react';
import reactDom from 'react-dom';
import { Card, CardHeader, CardBody, Col, Row, Button, ButtonGroup, FormGroup, Label, Input } from 'reactstrap';
import { useMyExams } from 'hooks/useMyExams';


const FinishedExam = ({ examTitle, onSuccess }) => {
    const myExam = useMyExams();
    return (
        <>
            {myExam?.isFinished && myExam?.checkAnswerData &&

                <Fragment>
                    <Row>
                        <Col md={12} className="pt-1">
                            ผลสอบ :: {examTitle}
                        </Col>
                    </Row>

                    {/* <Row className="pt-3">
                        <Col md={11} className="pt-1 mr-2">
                            ผลสอบ
                        </Col>
                    </Row> */}
                    <Row className="pt-3">
                        <Col md={2} className="pt-1">

                        </Col>
                        <Col md={12} className="pl-md-2 ml-2">
                            <FormGroup tag="fieldset">
                                <legend>ถูกทั้งหมด {myExam?.checkAnswerData?.correctTest} ข้อ จากข้อสอบทั้งหมด  {myExam?.checkAnswerData?.allTest} ข้อ</legend>

                            </FormGroup>
                            <Button className="" color={`primary`}
                                onClick={() => {
                                    if (onSuccess)
                                        onSuccess();

                                }}>{`ตกลง`}</Button>

                        </Col>
                    </Row>
                </Fragment>}
        </>

    );
}

export default FinishedExam;