/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Controller } from 'react-hook-form';


const FormInput = ({ as, control, error, ...rest }) => (
    <Controller as={as} control={control}  {...rest} />
);
FormInput.defaultProps = {
    as: Input
}

export default FormInput;
