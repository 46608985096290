/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

const playerAtom = atom({
    key: 'player',
    default: true
});

const playerDataAtom = atom({
    key: 'playerData',
    default: {}
});

export { usePlayVideo };
function usePlayVideo() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [isPlay, setPlayState] = useRecoilState(playerAtom);
    const [playData, setPlayDataState] = useRecoilState(playerDataAtom);

    return {
        setPlay,
        setDisablePlay,
        isPlay: isPlay,
        playData: playData
    }


    function setPlay({ url, datas, sourceList, isAllow, onRefreshCourse, onCompleteCourse, isComplete }) {
        setPlayState(false);
        setPlayDataState({ url, datas, sourceList, isAllow, onRefreshCourse, onCompleteCourse, isComplete });
    }

    function setDisablePlay() {
        setPlayState(true)
        setPlayDataState({});
    }
}
