/* eslint-disable */
import React from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, Input, Spinner, Alert } from 'reactstrap';
import { useHistory } from "react-router-dom";
import './index.scss';


const InfoCard = ({ title, message, onResetPassword, isLoading, setIsLoading, errors }) => {

    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState(null);

    const history = useHistory();
    return (
        <Row >
            <Col sm="4" className='container center'>
                <Card body>
                    <CardTitle>{title}</CardTitle>
                    <CardText>{message}</CardText>

                    {/* <CardText>errors : {errorMessage}</CardText> */}
                    <Input onChange={(e) => setEmail(e.target.value)} disabled={isLoading} />
                    {isLoading &&
                        <Col className='container center'>
                            <Spinner animation="border" variant="primary" className="mt-3" />
                        </Col>}
                    {!isLoading &&
                        <Button color='primary' className='mt-1'
                            disabled={email == ""}
                            onClick={() => {
                                if (onResetPassword()) {
                                    setErrorMessage(null);
                                    onResetPassword(email)
                                        .catch((error) => {
                                            setErrorMessage(error.response?.data);
                                            setIsLoading(false);
                                        });
                                }
                            }}

                        >Reset password</Button>}

                    {errorMessage &&
                        <Alert color="danger" className='mt-2'>
                            {errorMessage}
                        </Alert>
                    }
                </Card>
            </Col>
            {/* <Col sm="6">
                <Card body>
                    <CardTitle>Special Title Treatment</CardTitle>
                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                    <Button>Go somewhere</Button>
                </Card>
            </Col> */}
        </Row>
        //     <Row>
        //         <Col sm="6">
        //             <Card body>
        //                 <CardTitle>Special Title Treatment</CardTitle>
        //                 <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        //                 <Button>Go somewhere</Button>
        //             </Card>
        //         </Col>
        //         <Col sm="6">
        //             <Card body>
        //                 <CardTitle>Special Title Treatment</CardTitle>
        //                 <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
        //                 <Button>Go somewhere</Button>
        //             </Card>
        //         </Col>
        //     </Row>
    );
};

export default InfoCard;