/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Card,
    CardImg,
    CardBody,
    CardText,
    ButtonToggle,
} from "reactstrap";
import { useVerifyAccount } from "hooks/useVerifyAccount";

const ResetPassword = () => {
    const { resetCode } = useParams();
    const history = useHistory();
    const verifyAccount = useVerifyAccount();

    useEffect(() => {

        console.log(' verify - code')

        //if (verifyCode != null && verifyCode != "")
        //  verifyAccount.onVerifyAccount(verifyCode);
    }, [resetCode])


    // useEffect(() => {
    //     if (verifyAccount?.datas == true)
    //         history.push('/login');
    // }, [verifyAccount?.datas])

    return (
        <Container fluid className="Course pt-5 text-dark">
            <Row>
                <Col xl="9">
                    {resetCode}
                </Col>
            </Row>
        </Container>
    )
}

export default ResetPassword;