/* eslint-disable */
import React, { createRef, useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  //   CardTitle,
  //   CardText,
  Row,
  Col,
  Table,
  CardImg,
  CardBody,
  Form,
} from "reactstrap";
import FormInput from "components/FormInput";
import FormGroupInput from "components/FormGroupInput"
import InputField from "components/InputField/InputField";
import Flex from "components/Flex"
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import { showModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch } from "react-redux";
//import { Link, NavLink } from "react-router-dom";
import i18n from 'i18next';
import "./style.scss";
import { usePlayVideo } from "hooks/usePlayVideo";
import { useAuthen } from "hooks/useAuthen";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import LearningCourse from './learning-course';
import PassedCourse from './passed-course';



const UserInfomation = (props) => {
  let table = createRef();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const playerActions = usePlayVideo();
  const userActions = useAuthen();
  const history = useHistory();

  const { courses } = props;
  const [activeTab, setActiveTab] = useState("1");
  const { t } = useTranslation();
  const getLanguage = () => i18next.language || window.localStorage.i18nextLng;
  const dispatch = useDispatch();

  const handlePlayModalOpening = () => {
    // let datas = {
    //   url: _url,
    //   datas: {}
    // };
    // playerActions.setPlay(datas);
  }

  const Submit = (data) => {

  };


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Status',
      classes: 'py-2 align-middle',
      sort: true
    },
    {
      dataField: "courseName",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "revisionNo",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "positin",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "department",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "team",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "jobDescription",
      text: "",
      className: "text-white",
      align: "right",
    },
    {
      dataField: "dayLeft",
      text: "",
      className: "text-white",
      align: "right",
    },
  ];


  //console.log("CourseDetail >>", props);
  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" }, 'nav_link')}
            onClick={() => {
              toggle("1");
            }}
          >
            {i18n.language == 'en' ? `learning` : `กำลังเรียน`}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" }, 'nav_link')}
            onClick={() => {
              toggle("2");
            }}
          >
            {i18n.language == 'en' ? `passed` : `ผ่านแล้ว`}

          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" }, 'nav_link')}
            onClick={() => {
              toggle("3");
            }}
          >
            Cerificates
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="p-3">
          <LearningCourse courses={courses} />
        </TabPane>
        <TabPane tabId="2" className="p-3">
          <PassedCourse courses={courses} />
        </TabPane>
        {/* <TabPane tabId="3" className="p-3">

        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default UserInfomation;
