import 'bootstrap/dist/css/bootstrap.css';
import "./index.scss";
import "./i18n"
import App from "./App";
import { RecoilRoot } from 'recoil';
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
//import { fakeBackend } from './_helpers/fake-backend';
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

// setup fake backend

//fakeBackend();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
            <Suspense fallback={null}>
              <React.StrictMode>
                <RecoilRoot>
                  <App />
                </RecoilRoot>
              </React.StrictMode>
            </Suspense>
          </PersistGate>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
