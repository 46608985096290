/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useMyCourse };

const myCoursesAtom = atom({
    key: 'myCourses',
    default: []
});

const myCourseAtom = atom({
    key: 'myCourse',
    default: []
});

const myCourseLessonAtom = atom({
    key: 'myCourseLesson',
    default: []
});

const isRegisterCourseAtom = atom({
    key: 'isRegisterCourse',
    default: false
});


function useMyCourse() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();

    //const setMenu = useSetRecoilState(systemMuenAtom);
    //const menuValue = useRecoilValue(systemMuenAtom);

    const [mycourse, setMyCourse] = useRecoilState(myCourseAtom);
    const [mycourses, setMyCourses] = useRecoilState(myCoursesAtom);

    const [mylessons, setMyLessons] = useRecoilState(myCourseLessonAtom);
    const [isregister, setIsRegister] = useRecoilState(isRegisterCourseAtom);


    useMemo(() => {
        getMyCourse();
    }, []);



    return {
        registerCourse,
        isRegister, getMyCourseLessons, getMyCourseById,
        courses: mycourses, mycourse,
        lessons: mylessons,
        isregister: isregister

    }

    async function getMyCourse() {
        let req =
        {
            criteria: {
                searchText: ""
            },
            pageIndex: 0,
            pageSize: 24
        }

        return fetchWrapper.post(`${baseUrl}/my-courses/mylearning`, req)
            .then(response => {
                //console.log(response?.data)
                setMyCourses(response?.data);
            });
    }


    async function getMyCourseLessons(myCourseId) {


        return fetchWrapper.get(`${baseUrl}/my-courses/${myCourseId}/mylessons`)
            .then(response => {
                //console.log(response?.data)
                setMyLessons(response?.data);
            });
    }

    async function getMyCourseById(myCourseId) {
        return fetchWrapper.get(`${baseUrl}/my-courses/${myCourseId}`)
            .then(response => {
                // console.log('888>>', response)
                setMyCourse(response);
            });
    }


    async function registerCourse(courseId) {
        let req =
        {
            //  "validationResult": {},
            // id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            // courseId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            // myCourseCode: "string 999",
            // myCourseName: "string 999",
            // myCourseNameLocale: "string 999"
        }
        return fetchWrapper.post(`${baseUrl}/courses/${courseId}/mycourses/create`, req)
            .then(response => {
                //console.log(response?.data)
                setMyCourses(response?.data);
            });
    }

    async function isRegister(_courseId) {
        setIsRegister(false);
        return fetchWrapper.get(`${baseUrl}/my-courses`)
            .then(response => {
                response?.data?.filter(({ courseId }) => courseId == _courseId).map(c => {
                    setIsRegister(true);
                })
            });
    }



}