import { createSelector } from "reselect";


const selectPlayModal = state => state.playModal;


export const selectPlayModalState = createSelector(
	[selectPlayModal],
	playModal => playModal.modalPlayIsClosed
)

export const selectPlayModalContent = createSelector(
	[selectPlayModal],
	playModal => playModal.modalPlayContent
)