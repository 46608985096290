/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
} from "reactstrap";


import Certificate from "components/Certificate";

const CertificatePage = () => {
    const { mycourseId, myCourseOwnerBy } = useParams();
    return (
        <Container style={{ paddingTop: 60 }}>
            <Certificate mycourseId={mycourseId} myCourseOwnerBy={myCourseOwnerBy} />
        </Container>
    )
}

export default CertificatePage;