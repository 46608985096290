/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    //   CardTitle,
    //   CardText,
    Row,
    Col,
    Table,
    CardImg,
} from "reactstrap";

import i18n from "i18next";
import { FaPlay, FaCheckDouble, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import { usePlayVideo } from "hooks/usePlayVideo";
import { useExamModal } from "hooks/useExamModal";
import { useAuthen } from "hooks/useAuthen";


const LearningCourse = ({ courses, isRegister }) => {

    const playerActions = usePlayVideo();
    const examModal = useExamModal();
    const userActions = useAuthen();

    useEffect(() => {
        console.log('courses?.categories');
        console.log(courses?.categories)
    }, [])

    return (
        <>
            {courses?.map((c) => {
                if (c?.items?.length > 0) //{
                    return (
                        <>
                            {/* <NavLink
                                onClick={() => {
                                    //
                                }}
                            >
                               {c.productCategoryName} ({c?.items?.length})
                            </NavLink> */}

                            <Table striped >
                                <tbody>
                                    {c?.items?.map(item => {
                                        if (!item?.courses?.isComplete)
                                            return (
                                                <tr>
                                                    <td >
                                                        {c.productCategoryName}
                                                    </td>
                                                    <td >
                                                        {item?.courses?.courseCode}
                                                    </td>
                                                    <td>
                                                        <Link to={`/course/${item?.courses?.id}`}>
                                                            {i18n.language == 'en' ? item?.courses?.courseName : item?.courses?.courseNameLocale}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                        </>

                    )
                // }
            })}

        </>)
}

export default LearningCourse;