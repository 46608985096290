/* eslint-disable */
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

const certificateAtom = atom({
    key: 'certificate',
    default: true
});

const certificateDataAtom = atom({
    key: 'certificateData',
    default: {}
});

export { useShowCertificate };
function useShowCertificate() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [isShow, setShowState] = useRecoilState(certificateAtom);
    const [showData, setShowDataState] = useRecoilState(certificateDataAtom);

    return {
        setShow,
        setDisableShow,
        isShow,
        showData
    }


    function setShow({ url, datas, sourceList, isAllow, onRefreshCourse, onCompleteCourse, isComplete }) {
        setShowState(false);
        setShowDataState({ url, datas, sourceList, isAllow, onRefreshCourse, onCompleteCourse, isComplete });
    }

    function setDisableShow() {
        setShowState(true)
        setShowDataState({});
    }
}
