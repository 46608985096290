/* eslint-disable */
import React, { Fragment } from 'react';
import reactDom from 'react-dom';
import { Card, CardHeader, CardBody, Col, Row, Button, ButtonGroup, FormGroup, Label, Input, CardImg } from 'reactstrap';

import { useMyTests } from 'hooks/useMyTests';
import { useMyExams } from 'hooks/useMyExams';

//const lessons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 25, 26, 27, 28, 29, 30];
//const tests = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const LessonBox = ({ examTitle, examTests, examId, lessonId, courseId }) => {
    const [currentTest, sestCurrentTest] = React.useState(0);
    const [currentAnswer, setCurrentAnswer] = React.useState();
    const [isFinished, setIsFinished] = React.useState(false);
    const myTests = useMyTests();
    const myExam = useMyExams();


    const handleSendAnswer = (e) => {

        if (currentAnswer) {
            if (currentTest < examTests.length - 1) {
                let newTest = currentTest + 1;
                //console.log(newTest);
                sestCurrentTest(newTest);
                setCurrentAnswer(null);
            }

            // console.log(currentTest, examTests.length - 1)

            let req = {
                courseId: courseId,
                testId: examTests[currentTest]?.id,
                answerId: currentAnswer,
                examId: examId,
                lessonId: lessonId,
                isComplete: (currentTest == (examTests.length - 1))
            }

            myTests.onCreateMyTestAnswer(req);

            if (currentTest == (examTests.length - 1)) {
                // finish
                setIsFinished(true);
                myExam.setIsFinished(true);

                setTimeout(() => {
                    // setMapWidth(egMap.current.offsetWidth);
                    myExam.onCheckAnswer(examId);
                }, 1000);

            }

        } else {
            console.log("no answer !!!! ")
        }


    }

    const handleReset = (e) => {

        let newTest = 0;
        sestCurrentTest(newTest);
    }


    const handleSelectAnswer = (e) => {
        console.log(e.target.value)
        setCurrentAnswer(e.target.value);
    }


    // const handlePlayAnimation = event => {
    // 	event.stopPropagation();
    // 	handleModalClose();
    // };

    return (
        <>
            {!myExam.isFinished &&
                <Fragment>
                    <Row>
                        <Col md={12} className="pt-1">
                            {examTitle}
                        </Col>
                    </Row>
                    {/* <Row >

                <Col md={11} className="pl-md-2 ">
                    <div>
                        <ButtonGroup>
                            {examTests.map((l, idx) => (
                                <Button className="" color={idx == currentTest ? `primary` : `secondary`}

                                >{` ${idx + 1} `}</Button>
                            ))}
                        </ButtonGroup>

                         <Button color="primary">1</Button>{' '}
                    <Button color="secondary">2</Button>{' '}
                    <Button color="success">3</Button>{' '}
                    <Button color="info">4</Button>{' '}
                    <Button color="warning">5</Button>{' '}
                    <Button color="danger">6</Button>{' '}
                    <Button color="link">7</Button>
                    </div>
                </Col>
            </Row> */}
                    {examTests[currentTest]?.testImageUrl &&
                        <Row className="pt-3">

                            <Col md={11} className="pt-1 mr-2">
                                <img src={examTests[currentTest]?.testImageUrl} />
                                {/* <CardImg
                                    top
                                    width="auto"
                                    src={examTests[currentTest]?.testImageUrl}
                                    alt="Card image cap"
                                /> */}

                            </Col>
                        </Row>
                    }
                    <Row className="pt-3">


                        <Col md={11} className="pt-1 mr-2">
                            คำถาม  {currentTest + 1}.)  {examTests[currentTest]?.testName}
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col md={2} className="pt-1">

                        </Col>
                        <Col md={12} className="pl-md-2 ml-2">
                            <FormGroup tag="fieldset">
                                <legend>เลือกระหว่างคำตอบต่อไปนี้</legend>
                                {examTests[currentTest]?.choices?.map(choice => (
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name={`radio${examTests[currentTest]?.id}`} value={choice.id}
                                                checked={currentAnswer == choice.id}
                                                onClick={handleSelectAnswer}
                                            // onChange={handleSelectAnswer}
                                            />{' '}
                                            {choice.choiceName}
                                        </Label>
                                    </FormGroup>
                                ))}
                            </FormGroup>
                            <Button className="" color={`primary`}
                                onClick={handleSendAnswer}>{`ส่งคำตอบ`}</Button>

                            {/* <Button className="ml-2" color={`primary`}
                        onClick={handleReset}>{`เริ่มใหม่`}</Button> */}


                        </Col>
                        {/* <Col md={1} className="pt-1">

                </Col> */}
                    </Row>
                    {/* {isFinished && myExam?.checkAnswerData &&
                <Row>
                    <Col md={12} className="pt-1">
                        ถูกทั้งหมด {myExam?.checkAnswerData?.correctTest} ข้อ จากข้อสอบทั้งหมด  {myExam?.checkAnswerData?.allTest} ข้อ
                    </Col>
                </Row>} */}



                </Fragment>}
        </>
    );
}

export default LessonBox;