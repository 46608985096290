/* eslint-disable */
// import 'bootstrap/dist/css/bootstrap.css';
import "./style.scss";
import React, { useEffect, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
  CardBody,
  CardText,
  ButtonToggle,
} from "reactstrap";
import UserInfomation from "components/UserInfomation";

import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useRecoilValue } from "recoil";
import { courseAtom } from "_state";
import { useCourseActions } from "_actions";
import { useMyCourse } from "hooks/useMyCourse";
import { useAuthen } from "hooks/useAuthen";
import { useCourse } from "hooks/useCourse";
import { useUserInfo } from "hooks/useUserInfo";
import { useState } from "react";
import i18n from "i18next";


// import { motion } from "framer-motion";
// import { defaultPageFadeInVariants } from "../../motionUtils";






const UserProfile = () => {

  //const { courseId } = useParams();
  const courseId = '09f064d8-1f74-4c52-b70a-e0af724f854a';
  const { t } = useTranslation();

  const courseActions = useCourseActions();
  //const course = courseActions.Courses;
  const course = useRecoilValue(courseAtom);
  const auth = useAuthen();
  const mycourse = useMyCourse();
  const userInfo = useUserInfo();

  const [allLearningCourse, setAllLearningCourse] = React.useState(0);
  const [currentLearningCourse, setCurrentLearningCourse] = React.useState(0);
  const [courseHours, setCourseHours] = React.useState(0);



  const [learningCourses, setLearningCourses] = useState([]);

  //const courseActions = useCourseActions();

  const courses = useCourse();

  useEffect(() => {
    courses.getCategories('learning')
    userInfo.onGetUserInfo();
  }, [])


  useEffect(() => {
    //console.log("xxxx")
    //console.log(courses?.categories)
    setLearningCourses(courses?.categories);

  }, [courses?.categories])



  const summaryData = [
    {
      id: uuid(),
      name: t('course_learning_fullname_label'),
      value: userInfo?.datas?.firstName
    },
    // {
    //   id: uuid(),
    //   name: 'ตำแหน้งงาน',
    //   value: 'พนังงาน'
    // },
    {
      id: uuid(),
      name: t('course_learning_courses_label'),
      value: allLearningCourse + ' ' + t('couse_unit_label')
    },
    {
      id: uuid(),
      name: t('course_learning_hours_label'),
      value: courseHours + ' ' + t('course_lesson_hours')
    },
    {
      id: uuid(),
      name: t('course_learning_label'),
      value: currentLearningCourse + ' ' + t('couse_unit_label')
    },
    // {
    //   id: uuid(),
    //   name: t('course_lastupdate_label'),
    //   value: '10/10/2021'
    // }
  ];


  // useMemo( () => {
  //   const courseActions = useCourseActions();
  //   const courseData = await courseActions.getAll();
  //   console.log(courseData)
  // }, [])


  useEffect(() => {
    courseActions.getCourse(courseId); //"fe1be5f3-5fa6-4c8b-8416-b72331b0ee81"

    //console.log(mycourse?.courses);

    //course = courseActions.Courses;
  }, [])


  useEffect(() => {
    //console.log("mycourse >>> ");
    //console.log(mycourse?.courses);

    mycourse.isRegister(courseId)

    let _mycourseTemp = [];

    mycourse?.courses?.filter(({ items }) => items.length > 0).map(course =>
      course.items.map(itm => {
        _mycourseTemp.push(itm?.courses);
        //return itm?.courses;
      })
      //return itm.items[0]?.courses;
    );
    //console.log('_mycouse ', _mycourseTemp);

    setAllLearningCourse(_mycourseTemp.length);
    setCurrentLearningCourse(_mycourseTemp.filter(({ isComplete }) => isComplete != true).length)
    const summaryHours = _mycourseTemp.filter(({ isComplete }) => isComplete == true)?.reduce((total, currentValue) => total = total + currentValue.courseHours, 0);
    setCourseHours(summaryHours);

    //console.log('_summaryData', _mycourseTemp.filter(({ isComplete }) => isComplete == true));

    //course = courseActions.Courses;
  }, [mycourse?.courses])



  useEffect(() => {

    //console.log(course())
    console.log(course)
  }, [course])


  return (
    <Container fluid className="Course pt-5">
      <Row>
        <Col xl="9">
          {mycourse?.courses &&
            <UserInfomation courses={mycourse?.courses} learningCourses={mycourse?.courses} />}
        </Col>
        <Col xl="3">
          <div>
            <Card className="border-0">
              {/* <CardImg
                top
                width="100%"
                src="https://mylearn-api.devz.sbs/images/products/elearning/nDLylQOoIazGyYuWhk21Yww5FCb.jpg"
                alt="Card image cap"
              /> */}
              <CardBody>
                <CardText>
                  <ListGroup flush>
                    {summaryData.map(itm => (
                      <ListGroupItem className="bg-transparent" >
                        <span>{itm.name}</span>
                        <span className="float-right">{itm.value}</span>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardText>

              </CardBody>
            </Card>
          </div>
          <div className="text-center m-5">
            {/* {(auth.auth && course) &&
              <ButtonToggle color={!mycourse?.isregister ? "primary" : "secondary"} className="btn-lg"
                disabled={mycourse?.isregister}
                onClick={() => mycourse?.registerCourse(course?.id)}
              >
                {t(mycourse?.isregister ? 'couse_already_register_label' : 'couse_register_label')}
              </ButtonToggle>} */}
          </div>
        </Col>
      </Row>
    </Container >
  );
};

export default UserProfile;
