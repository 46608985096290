/* eslint-disable */
import './playModal.scss'
import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"
import { staggerOne, modalOverlayVariants, modalVariants, modalFadeInUpVariants } from "../../motionUtils";
import { hideModalPlay } from "../../redux/playmodal/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectPlayModalContent, selectPlayModalState } from "../../redux/playmodal/modal.selectors";
import { BASE_IMG_URL, FALLBACK_IMG_URL, BASE_API_URL } from "../../requests";
import { VscChromeClose } from "react-icons/vsc";
import { dateToYearOnly } from "_helpers/utils";
import { FaMinus, FaPlay, FaPlus, FaHeart } from "react-icons/fa";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { addToFavourites, removeFromFavourites } from "../../redux/favourites/favourites.actions";
import useOutsideClick from "../../hooks/useOutsideClick";
import ReactPlayer from 'react-player/lazy';
import VideoPlayer from './VideoPlayer';

import { usePlayVideo } from "hooks/usePlayVideo";
import { useMyLessons } from "hooks/useMyLessons"
import { useMyLessonMedias } from 'hooks/useMyLessonMedias';
import { useAuthen } from 'hooks/useAuthen';

// ref = player => {
// 	this.player = player
// }


const PlayModal = () => {


	const player = useRef();
	const playerActions = usePlayVideo();
	const myLessons = useMyLessons();
	const myLessonMediaa = useMyLessonMedias();
	const authen = useAuthen();

	const auth = authen.auth;


	const dispatch = useDispatch();
	const modalClosed = playerActions.isPlay;//useSelector(selectPlayModalState);
	const modalData = playerActions.playData;
	const modalContent = useSelector(selectPlayModalContent);
	const [playPosition, setPlayPosition] = React.useState(0);
	const [playEnded, setPlayEnded] = React.useState(false);
	const [playing, setPlaying] = React.useState(true);




	const { item, courseId, overview, code, fallbackTitle, titleImageUrl, backdrop_path, release_date, first_air_date, genresConverted, isFavourite, poster } = modalContent;
	const joinedGenres = genresConverted ? genresConverted.join(', ') : "Not available";
	//const maturityRating = adult === undefined ? "Not available" : adult ? "Suitable for adults only" : "Suitable for all ages";
	const reducedDate = release_date ? dateToYearOnly(release_date) : first_air_date ? dateToYearOnly(first_air_date) : "Not Available";
	const modalRef = useRef();

	const handleModalClose = () => {
		console.log("stop at >> ", playPosition);
		console.log("courseId >> ", modalData?.datas?.courseId);
		console.log("lessonId >> ", modalData?.datas?.lessonId);

		if (modalData?.onRefreshCourse) {
			console.log(' refreshing......')
			modalData?.onRefreshCourse(modalData?.datas?.courseId)
		}

		if (modalData?.onCompleteCourse) {
			console.log(' onCompleteCourse......')
			modalData?.onCompleteCourse(modalData?.datas?.courseId)
		}

		playerActions.setDisablePlay()
	};


	const handlePlayState = (e) => {
		//console.log('play state')
		setPlayPosition(e.currentTime);

		if (!playEnded && e.ended) {
			setPlayEnded(true)
		}

		//if (e.ended)
		// myLessons.onUpdate(modalData?.datas?.courseId, modalData?.datas?.lessonId, {
		// 	videoPosition: e.currenttime,
		// 	IsComplete: e.ended
		// });
	}

	const handleAdd = (event) => {
		event.stopPropagation();
		dispatch(addToFavourites({ ...modalContent, isFavourite }));
	}
	const handleRemove = (event) => {
		event.stopPropagation();
		dispatch(removeFromFavourites({ ...modalContent, isFavourite }));
		if (!modalClosed) handleModalClose();
	}
	const handlePlayAnimation = event => {
		event.stopPropagation();
		handleModalClose();
	};
	useOutsideClick(modalRef, () => {
		//if (!modalClosed) handleModalClose();
	});

	const handleProgress = state => {
		console.log('onProgress', state)

		// We only want to update time slider if we are not currently seeking
		if (!state.seeking) {
			//this.setState(state)
			setPlayPosition(state.playedSeconds);
		}

		console.log('playPosition', playPosition)
	}

	const handlePause = () => {
		console.log('onPause')
		setPlaying(false);
		//this.setState({ playing: false })
	}

	const handleSeek = (e) => {
		setPlaying(false);
		console.log('onSeek');
		console.log(e);
		console.log('playPosition >> ', playPosition);


		if (e > playPosition) {
			//console.log('not allow')
			//console.log(player)
			player.current.seekTo(parseFloat(playPosition))
			setPlaying(false);
			//setPlaying(true)
		}


		//this.setState({ playing: false })
	}

	const handleDuration = (duration) => {
		console.log('onDuration', duration)
		//this.setState({ duration })
	}


	useEffect(() => {
		console.log('modalData >>>');
		console.log(modalData);
	}, [modalData]);

	return (
		<AnimatePresence exitBeforeEnter>
			{!modalClosed && (
				<>
					<motion.div
						variants={modalOverlayVariants}
						initial="hidden"
						animate="visible"
						exit="hidden"
						key="modalOverlay"
						className={`Modal__overlay ${modalClosed && 'Modal__invisible'}`}
					>
						<motion.div
							key="modal"
							variants={modalVariants}
							ref={modalRef}
							style={{ height: 'auto' }}
							className={`Modal__wrp ${modalClosed && 'Modal__invisible'}`}
						>
							<motion.button
								className="Modal__closebtn"
								onClick={handleModalClose}
							>
								<VscChromeClose />
							</motion.button>
							<VideoPlayer
								url={modalData?.url}
								courseId={modalData?.datas?.courseId}
								allowReplay={modalData?.isAllow && modalData?.isComplete}
								onPlayState={handlePlayState}
								sourceList={modalData?.sourceList}
								onUpdateMyMedia={myLessonMediaa.onLearningUpdate}
								isAllow={modalData?.isAllow}
								isComplete={modalData?.isComplete}
								onClose={handleModalClose}
								isTestMode={auth?.isTestMode}

							/>
							{/* <ReactPlayer url={`${modalData?.url}`} className="Modal__image--img"

								//ref={player}
								ref={player}
								controls={true}
								width='100%'
								height='100%'
								playing={playing}
								onProgress={handleProgress}
								onDuration={handleDuration}
								onPause={handlePause}
								onSeek={handleSeek}
							/> */}
							<div className="Modal__image--wrp">
								<div className="Modal__image--shadow" />

								{/* <img
									className="Modal__image--img"

									src={poster ? poster?.posterUrl : (poster?.posterUrl ? poster?.posterUrl : FALLBACK_IMG_URL)}
									alt={fallbackTitle}
								/> */}
								{/* <div className="Modal__image--buttonswrp">
									<Link
										className="Modal__image--button"
										onClick={handlePlayAnimation}
										// to={'/play'}
										to={`/course/${courseId}`}
									>
										<FaPlay />
										<span>ลงทะเบียน / เรียน</span>
									</Link>
									{!isFavourite
										? (
											<button className='Modal__image--button-circular' onClick={handleAdd}>
												<FaHeart />
											</button>
										) : (
											<button className='Modal__image--button-circular' onClick={handleRemove}>
												<FaHeart />
											</button>
										)}
								</div> */}
							</div>

						</motion.div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	)
}

export default PlayModal
