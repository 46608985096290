/* eslint-disable */
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { history, useFetchWrapper, apiBaseURL } from '_helpers';
import { courseAtom } from '_state';

export { useCourseActions };



function useCourseActions() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    //const setAuth = useSetRecoilState(authAtom);
    const setCourse = useSetRecoilState(courseAtom);

    return {
        getAll,
        getCourse,
        Courses, completeCourse
    }

    function getCourse(id) {
        setCourse(null);
        return fetchWrapper.get(`${baseUrl}/courses/${id}`)
            .then(response => {
                setCourse(response);
            });
    }

    function completeCourse(id) {

        ///api/my-courses/{myCourseId}/complete
        return fetchWrapper.put(`${baseUrl}/my-courses/${id}/complete`)
            .then(response => {
                // setCourse(response);
            });
    }

    function Courses() {
        return useRecoilValue(courseAtom);
    };


    function getAll() {
        return fetchWrapper.get(`${baseUrl}/courses/fe1be5f3-5fa6-4c8b-8416-b72331b0ee81`).then(setCourse);
    }
}


// export const useWishlistState = () => {
//     return useRecoilState(wishlistAtom);
//   };

//   export const useWishlistStateValue = () => {
//     return useRecoilValue(wishlistAtom);
//   };

//   export const useSetWishlistState = () => {
//     return useSetRecoilState(wishlistAtom);
//   };

// usage = const allWishlist = useWishlistStateValue();