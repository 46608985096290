/* eslint-disable */
import { useMemo } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, atom } from 'recoil';
import { history, useFetchWrapper, apiBaseURL } from '_helpers';

export { useChangePassword };

const dataAtom = atom({
    key: 'changePassword',
    default: false
});


const userInfoAtom = atom({
    key: 'userInfo',
    default: {}
});


function useChangePassword() {
    const baseUrl = `${apiBaseURL}`;
    const fetchWrapper = useFetchWrapper();
    const [datas, setDatas] = useRecoilState(dataAtom);
    const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);


    return {
        onChangePassword, onGetUserInfo,
        datas, userInfo
    }
    async function onChangePassword(req) {
        return changePassword(req)
    }

    async function onGetUserInfo(req) {
        return getUserInfo(req)
    }

    async function changePassword(req) {
        return fetchWrapper.post(`${baseUrl}/accounts/change-password`, req)
            .then(response => {
                //console.log('verify - response ')
                //console.log(response)
                setDatas(response);
            });
    }


    async function getUserInfo(req) {
        return fetchWrapper.get(`${baseUrl}/accounts/info`, req)
            .then(response => {
                //console.log('verify - response ')
                //console.log(response)
                setUserInfo(response);
            });
    }





}